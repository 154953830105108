<template v-if="value">
  <!-- Куратор закрыл / проверил экспертизу -->
  <v-icon
    v-if="value.is_checked"
    color="secondary"
    right
    title="Экспертиза проверена и закрыта"
    v-bind="$attrs"
    >mdi-flag-checkered</v-icon
  >
  <!-- Эксперт завершил экспертизу -->
  <v-icon
    v-else-if="!value.is_compare && value.is_completed"
    color="success"
    right
    title="Экспертиза завершена и отправлена на сравнение"
    v-bind="$attrs"
    >mdi-check</v-icon
  >
  <!-- Оба эксперта завершили экспертизу -->
  <v-icon
    v-else-if="value.is_compare && value.is_completed"
    color="success"
    right
    title="Экспертиза готова к сравнению версий"
    v-bind="$attrs"
    >mdi-check-all</v-icon
  >
  <!-- Один из экспертов завершил экспертизу -->
  <v-icon
    v-else-if="
      value.is_compare && (value.is_completed_1 || value.is_completed_2)
    "
    right
    title="Один из экспертов готов к сравнению"
    v-bind="$attrs"
    >mdi-check</v-icon
  >
  <!-- Оба эксперта завершили аналоги -->
  <v-icon
    v-else-if="!value.is_compare && value.is_analog_completed"
    color="success"
    right
    title="Аналоги готовы к сравнению версий"
    v-bind="$attrs"
    >mdi-clock-check-outline</v-icon
  >
  <!-- Один из экспертов завершил экспертизу -->
  <v-icon
    v-else-if="
      value.is_compare &&
      (value.is_analog_completed_1 || value.is_analog_completed_2)
    "
    right
    title="Один из экспертов готов к сравнению аналогов"
    v-bind="$attrs"
    >mdi-clock-check-outline</v-icon
  >
</template>

<script>
export default {
  name: 'Icon-Compleated',
  props: {
    value: { type: Object, default: () => ({}) },
  },
}
</script>
