/*
  Хранилише для сравнений экспертиз (для куратора) 
  приставка compare_ для отделения экшенов, мутаций и геттеров 
*/
import { Api } from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'

const cCOMPRESS = true
const cDATA = ''
const cVERSION = '-date'

import {
  copyObject,
  sameObject,
  objectByPath,
  stringToPath,
} from '@/lib/objects.js'
import { EX, sub_exp_migration, sub_exp_version } from './../const/expertise'

import Vue from 'vue'

const state = {
  loading: 0,
  loadingExp: [0, 0],
  error: '',

  // селекторы экспертизы
  plp_id: null, // просто номер для сохраннения в LS
  ex_type: null,
  ex_type_sub: null,

  // пользователь [0, 1]
  user: [null, null],
  versionList: [null, null],
  expertise: [null, null],

  // итогавая версия
  data: null,

  // дата сохранения в LocalStorage
  local_save_date: '',
}

const getters = {
  compare_loading: state => !!state.loading,
  compare_loading_0: state => !!state.loadingExp[0],
  compare_loading_1: state => !!state.loadingExp[1],

  compare_error: state => state.error,
  /** активный тип экспертизы*/
  compare_ex_type: state => state.ex_type ?? '',
  /** активный тип под экспертизы */
  compare_ex_type_sub: state => state.ex_type_sub ?? '',

  // ================================================================
  /** задачи для пользователя в слоте === 0 */
  compare_task_0: (_, getters) =>
    getters.storedPlpTasks.find(
      ({ expertiseType, section }) =>
        expertiseType?.name === getters.compare_ex_type && section === 0
    ),
  compare_task_0_id: (_, getters) => getters.compare_task_0?.id,
  /** спискок версий для пользователя в слоте === 0 */
  compare_version_0_list: state => state.versionList[0],
  /** пользователь в слоте === 0 */
  compare_user_0: (_, getters) => getters.compare_task_0?.user,
  /** экспертиза для пользователя в слоте === 0 */
  compare_expertise_0: state => state.expertise[0],
  compare_expertise_0_Data: state => state.expertise[0]?.data,
  compare_expertise_0_UUID: state => state.expertise[0]?.version_uuid ?? null,

  // ================================================================
  /** задачи для пользователя в слоте === 1 */
  compare_task_1: (_, getters) =>
    getters.storedPlpTasks.find(
      ({ expertiseType, section }) =>
        expertiseType?.name === getters.compare_ex_type && section === 1
    ),
  compare_task_1_id: (_, getters) => getters.compare_task_1?.id,
  /** спискок версий для пользователя в слоте === 1 */
  compare_version_1_list: state => state.versionList[1],
  /** пользователь в слоте === 1 */
  compare_user_1: (_, getters) => getters.compare_task_1?.user,
  /** экспертиза для пользователя в слоте === 1 */
  compare_expertise_1: state => state.expertise[1],
  compare_expertise_1_Data: state => state.expertise[1]?.data,
  compare_expertise_1_UUID: state => state.expertise[1]?.version_uuid ?? null,

  // ================================================================
  compare_data_ready: state => !!state.data,
  compare_data: state => state.data,
  compare_local_save_date: state => state.local_save_date,

  // ================================================================
  compare_expertise_type_id: (_, getters) =>
    getters.compare_task_0?.expertise_type_id ||
    getters.compare_task_1?.expertise_type_id,
  compare_expertise_checked: (_, getters) =>
    getters.compare_task_0?.is_checked || getters.compare_task_1?.is_checked,
}

/** Сохраняем промежуточные данные в LocalStorage */
function saveLocal({ plp_id, ex_type, ex_type_sub, data }) {
  // колюч как номер PLP - и тип экспертизы
  const key = `${plp_id}-${ex_type}-${ex_type_sub}`

  if (data) {
    const dt = new Date()
    ls.saveDict(key, dt.toLocaleString(), data, cCOMPRESS, cDATA, cVERSION)
  } else {
    ls.clearDict(key, cDATA, cVERSION)
  }
  state.local_save_date = '' // чистим после пересохраннения
}

/** Загружаем промежуточные данные из LocalStorage */
function loadLocal(state) {
  // колюч как номер PLP - и тип экспертизы
  const key = `${state.plp_id}-${state.ex_type}-${state.ex_type_sub}`

  try {
    state.local_save_date = ls.loadDictVersion(key, '', cVERSION)
    state.data = ls.loadDictData(key, cDATA, cVERSION)
  } catch {
    ls.clearDict(key, cDATA, cVERSION)
  }
  return null
}

/** Отчищаем промежуточные данные из LocalStorage для всего plp_id, ex_type */
function clearLocalForPlpId(plp_id, ex_type) {
  // колюч как номер PLP - и тип экспертизы
  EX[ex_type]?.forEach(ex_type_sub => {
    const key = `${plp_id}-${ex_type}-${ex_type_sub}`
    ls.clearDict(key, cDATA, cVERSION)
  })
}

function clearLocal(state) {
  // колюч как номер PLP - и тип экспертизы
  const key = `${state.plp_id}-${state.ex_type}-${state.ex_type_sub}`

  ls.clearDict(key, cDATA, cVERSION)
  state.local_save_date = '' // чистим после пересохраннения
}

const mutations = {
  COMPARE_CLEAR(state) {
    state.plp_id = null
    state.ex_type = null
    state.ex_type_sub = null

    state.expertise = [null, null]
    state.versionList = [null, null]
    state.loadingExp = [0, 0]
    state.loading = 0
    state.error = ''

    state.data = null
    state.local_save_date = ''
  },

  COMPARE_CLEAR_LOCAL(state) {
    clearLocal(state)
  },

  COMPARE_SET_EXPERTISE(state, { plp_id, ex_type, ex_type_sub }) {
    state.plp_id = plp_id ?? null
    state.ex_type = ex_type ?? null
    state.ex_type_sub = ex_type_sub ?? ''
    console.log('COMPARE_SET_EXPERTISE', plp_id, ex_type, ex_type_sub)

    // подгрузить если было локальное сохраннение??
    loadLocal(state)
  },

  COMPARE_SET_LOADING(state, st) {
    state.loading += st ? 1 : -1
  },

  COMPARE_SET_ERROR(state, error) {
    state.error = error
  },

  COMPARE_SET_VERSION_LIST(state, { index, list }) {
    console.log(
      'COMPARE_SET_VERSION_LIST',
      index,
      list?.length ? '' : 'is empty'
    )
    Vue.set(state.versionList, index, list || [])
  },

  COMPARE_SET_LOADING_EXPERTISE(state, { index, st }) {
    Vue.set(state.loadingExp, index, state.loadingExp[index] + (st ? 1 : -1))
  },

  COMPARE_SET_CURRENT_EXPERTISE(state, { index, data }) {
    console.log('COMPARE_SET_CURRENT_EXPERTISE', index, data)

    Vue.set(state.expertise, index, data)
  },

  /** Установка сводных данных - весь объект */
  COMPARE_DATA(state, data) {
    state.data = copyObject(data)
    // сохранить в localstorage
    saveLocal(state)
  },
  /** Установка сводных данных - по ключу */
  COMPARE_PART_DATA(state, { root, tag, value, tag2, value2 }) {
    // предварительная инициализация
    if (!state.data && (tag || root)) {
      state.data = {}
    }
    // путь в массив, если нет ключа - вытащим из пути
    root = stringToPath(root)
    if (!tag && root.length > 0) {
      tag = root.pop()
    }
    // объект назначение
    const destObj = objectByPath(state.data, root)
    // копия значения
    const copyValue = copyObject(value)
    // проверка на изменения
    const changed = tag
      ? !sameObject(destObj[tag], copyValue)
      : !sameObject(destObj, copyValue)

    if (!changed) return

    if (tag) {
      Vue.set(destObj, tag, copyValue)
      if (tag2) Vue.set(destObj, tag2, copyObject(value2))
    } else if (!root?.length) {
      state.data = copyValue
    } else {
      console.log('its impossible - fake', root, tag)
    }

    // сохранить в localstorage
    saveLocal(state)
  },
}

const actions = {
  /** Получить данные по таскам, юзерам, версиям и экспертизам для сравнения*/
  COMPARE_GET_EXPERTISE: async (
    { getters, commit, dispatch },
    { ex_type, ex_type_sub }
  ) => {
    commit('COMPARE_CLEAR')

    if (!ex_type || !ex_type_sub) return

    commit('COMPARE_SET_LOADING', true)
    try {
      const plp_id = getters.storedPlpId
      commit('COMPARE_SET_EXPERTISE', { plp_id, ex_type, ex_type_sub })

      // функция получения данных для одного слота
      const prepare = async index => {
        // #task_id, #user_id
        const { id } = getters[`compare_task_${index}`] || {}

        if (id) {
          // пробуем получить список версий
          const list = await Api.get(
            `plp-task-subexpertise-versions/get-by-task-and-type/${id}/${ex_type_sub}?versions`
          )
          // VersionList
          commit('COMPARE_SET_VERSION_LIST', { index, list })
          // после получения версии пробуем загрузить экспертизы
          const uuid = list?.[0]?.version_uuid
          if (uuid) dispatch('COMPARE_SET_EXPERTISE_VERSION', { index, uuid })
          //else throw new Error('fuck off')
        }
      }
      // ждёмс все запросы
      await Promise.all([prepare(0), prepare(1)])
    } catch (error) {
      commit('SET_ERROR', {
        head: 'COMPARE',
        text: 'Ошибка загружки экспертиз для сравнения',
        error,
      })
      commit('COMPARE_SET_ERROR', getters.lastError)
      throw error
    } finally {
      commit('COMPARE_SET_LOADING', false)
    }
  },

  /** выбираем версию по uuid */
  COMPARE_SET_EXPERTISE_VERSION: async (
    { commit, getters },
    { index, uuid }
  ) => {
    try {
      if (!uuid || uuid === getters[`compare_expertise_${index}_UUID`]) return

      // сбросить обычную
      commit('SET_CURRENT_EXP', {})

      commit('COMPARE_SET_LOADING_EXPERTISE', { index, st: true })

      const data = await Api.get(
        `plp-task-subexpertise-versions/get-by-version/${uuid}`
      )
      const { data_version, data: expData } = data

      commit('COMPARE_SET_CURRENT_EXPERTISE', {
        index,
        data: {
          ...data,
          ...(await sub_exp_migration(
            getters.compare_ex_type_sub,
            data_version,
            expData
          )),
        },
      })
    } catch (error) {
      commit('SET_ERROR', {
        head: 'COMPARE',
        text: `Ошибка загрузки экспертизы ${getters.compare_ex_type}:${getters.compare_ex_type_sub}`,
        error,
      })
      commit('COMPARE_SET_ERROR', getters.lastError)
      throw error
    } finally {
      commit('COMPARE_SET_LOADING_EXPERTISE', { index, st: false })
    }
  },

  /** Отправить сводную версию как результат сравнения версий */
  COMPARE_SET_MERGED_VERSION: async ({ getters, commit, dispatch }) => {
    try {
      const plp_id = getters.storedPlpId
      const expertise_type_id =
        getters.compare_task_0?.expertise_type_id ||
        getters.compare_task_1?.expertise_type_id
      const ex_type_name = getters.compare_ex_type_sub
      const dt = new Date()
      const version_name = `Сводная версия от ${dt.toLocaleString()}`
      // достаём и сохраняем версию данных экспертизы
      const data_version = await sub_exp_version(ex_type_name)

      if (!expertise_type_id || !ex_type_name || !data_version)
        throw new Error(
          'ВНИМАНИЕ!! Проблема с параметрами для сохраннения сводной версии'
        )

      // отправка на сервер сводной версии
      await Api.post('plp-task-subexpertise-versions/merge', {
        plp_id,
        expertise_type_id,
        ex_type_name,
        version_name,
        data: getters.compare_data,
        data_version,
      })
      // успех, чистим локальную копию
      commit('COMPARE_CLEAR_LOCAL')
      // сравнение (новые вресии)
      dispatch('COMPARE_GET_EXPERTISE', {
        ex_type: getters.compare_ex_type,
        ex_type_sub: getters.compare_ex_type_sub,
      })
    } catch (error) {
      commit('SET_ERROR', {
        head: 'COMPARE',
        text: `Ошибка сохранения сводной версии экспертизы ${getters.compare_ex_type}:${getters.compare_ex_type_sub}`,
        error,
      })
      commit('COMPARE_SET_ERROR', getters.lastError)
      throw error
    }
  },

  /** Чистим все сохранки для экспертизы по типу */
  COMPARE_CLEAR_PLP: (_, { plp_id, ex_type }) => {
    clearLocalForPlpId(plp_id, ex_type)
  },

  /** Отправляет таску для доработки */
  COMPARE_SEND_TO_REWORK: async ({ getters, commit, dispatch }, position) => {
    try {
      const t1 = position !== 2 ? getters.compare_task_0_id : undefined
      const t2 = position !== 1 ? getters.compare_task_1_id : undefined

      //send-to-rework
      await Promise.all([
        t1 ? Api.post(`plp-tasks/${t1}/send-to-rework`) : null,
        t2 ? Api.post(`plp-tasks/${t2}/send-to-rework`) : null,
      ])
      // обновить таски
      dispatch('RELOAD_PLP')
    } catch (error) {
      commit('SET_ERROR', {
        head: 'COMPARE',
        text: `Ошибка отправки экспертизы на доработку`,
        error,
      })
      commit('COMPARE_SET_ERROR', getters.lastError)
      throw error
    }
  },
  /** Принудительно закрываем таску для работы */
  COMPARE_FORCE_COMPLETED: async ({ getters, commit, dispatch }) => {
    try {
      const t1 = getters.compare_task_0_id
      const t2 = getters.compare_task_1_id
      //send-to-rework
      await Promise.all([
        t1 ? Api.post(`plp-tasks/${t1}/force-completed`) : null,
        t2 ? Api.post(`plp-tasks/${t2}/force-completed`) : null,
      ])
      // обновить таски
      dispatch('RELOAD_PLP')
    } catch (error) {
      commit('SET_ERROR', {
        head: 'COMPARE',
        text: `Ошибка принудительного закрытия экспертизы`,
        error,
      })
      commit('COMPARE_SET_ERROR', getters.lastError)
      throw error
    }
  },
  /** Метим экспертизу как проверенную и закрываем */
  COMPARE_MARK_AS_CHECKED: async (
    { getters, commit, dispatch },
    check = true
  ) => {
    try {
      const plp_id = getters.storedPlpId
      const expertise_type_id = getters.compare_expertise_type_id

      await Api.post(`plp-tasks/${check ? 'check' : 'uncheck'}`, {
        plp_id,
        expertise_type_id,
      }),
        // обновить таски
        dispatch('RELOAD_PLP')
    } catch (error) {
      commit('SET_ERROR', {
        head: 'COMPARE',
        text: `Ошибка закрытия этапа сравнения`,
        error,
      })
      commit('COMPARE_SET_ERROR', getters.lastError)
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
