/* eslint-disable no-dupe-class-members */
import axios from 'axios'

export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const PUT = 'PUT'

export class EApiException extends Error {
  constructor(code, message) {
    super((code ? `${code} - ` : '') + message)
    this.name = 'EApiException'
    this.code = code
  }
  toString() {
    return `${this.name}: ${this.message}`
  }
}

export class EApiDownloadFileException extends EApiException {
  constructor(fileName, error) {
    const msg = `Ошибка получения файла "${fileName}": ${error}`
    super(100, msg)
    this.name = 'EApiDownloadFileException'
    this.message = msg
    this.error = error
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiInternalException extends EApiException {
  constructor(
    code = 500,
    message = 'Внутренняя ошибка сервера или сервер не доступен!'
  ) {
    super(code, message)
    this.name = 'EApiInternalException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiConflictException extends EApiException {
  constructor(code = 409, message = 'Конфликт при изменения ресурса') {
    super(code, message)
    this.name = 'EApiConflictException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiTooManyRequestsException extends EApiException {
  constructor(
    code = 429,
    message = 'Слишком много запросов, попробуйте повторить чуть позже'
  ) {
    super(code, message)
    this.name = 'EApiTooManyRequestsException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiBadRequestException extends EApiException {
  constructor(message) {
    super(400, message)
    this.name = 'EApiBadRequestException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiForbiddenException extends EApiException {
  constructor(code = 403, message = 'Нет прав для доступа к ресурсу') {
    super(code, message)
    this.name = 'EApiForbiddenException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiUnauthorizedException extends EApiException {
  constructor(code = 401, message = 'Пользователь не авторизован!') {
    super(code, message)
    this.name = 'EApiUnauthorizedException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class Api {
  static axios = axios.create({
    baseURL: '/api/',
  })

  // скачивает файл с названием fileName по ссылке url (типа type файла из ответа берется)
  static async getFile(url, fileName) {
    try {
      const { data, headers } = await this.axios.get(url, {
        responseType: 'blob',
      })

      const blob = new Blob([data], {
        type: headers['content-type'],
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (e) {
      throw new EApiDownloadFileException(fileName, e)
    }
  }

  static createAbortController = () => new AbortController()

  static #doAxios(uri = '', data, method, config) {
    if (method === GET || !method) return this.axios.get(uri, config)
    if (method === POST) return this.axios.post(uri, data, config)
    if (method === PATCH) return this.axios.patch(uri, data, config)
    // special way to send axios delete request with body
    if (method === DELETE) return this.axios.delete(uri, { ...config, data })
    if (method === PUT) return this.axios.put(uri, data, config)
  }

  static #apiOnUnauthorized = null
  static #apiOnUnauthorizedLock = 0
  static #apiOnUnauthorizedLockVersion = 1

  static unauthorizedEvent(event) {
    if (typeof event === 'function' || !event) this.#apiOnUnauthorized = event
    else throw new Error('Api unauthorizedEvent error, event is not a function')
  }

  static setAccessToken(tokken) {
    if (tokken) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${String(
        tokken
      ).trim()}`
    } else {
      delete this.axios.defaults.headers.common['Authorization']
    }
  }

  static setApiVersion(version) {
    if (version) {
      this.axios.defaults.headers.common['X-Version'] = String(version).trim()
    } else {
      delete this.axios.defaults.headers.common['X-Version']
    }
  }

  static cancelUnauthorizedEventWaitQuery() {
    this.#apiOnUnauthorizedLockVersion++
  }

  static async #doUnauthorizedEvent(url) {
    // запомнили версию на входе
    const enterVersion = this.#apiOnUnauthorizedLockVersion

    while (this.#apiOnUnauthorizedLock) {
      // если есть блокировка то просто висим 100 мс и ждём
      await new Promise(r => setTimeout(r, 100))
      if (this.#apiOnUnauthorizedLockVersion > enterVersion) {
        console.log('I`m die...', url)
        throw new EApiException(0, 'Canceled') // выходим громко
      }
      if (!this.#apiOnUnauthorizedLock) return // выходим молча на 2 попытку
    }
    // если заскочили в проверку ... и есть событие
    if (typeof this.#apiOnUnauthorized === 'function') {
      try {
        this.#apiOnUnauthorizedLock++
        await this.#apiOnUnauthorized()
      } catch {
        throw new EApiUnauthorizedException()
      } finally {
        this.#apiOnUnauthorizedLock--
      }
    } else {
      // Что то сделать если нет авторизации
      console.info('Empty unauthorized event')
      throw new EApiUnauthorizedException()
    }
  }

  static get(url, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, undefined, GET, direct, configObj)
  }
  static post(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, POST, direct, configObj)
  }
  static patch(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, PATCH, direct, configObj)
  }
  static put(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, PUT, direct, configObj)
  }
  static delete(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, DELETE, direct, configObj)
  }

  static async call(url, data, method, direct = false, config) {
    // функция для повтора
    const retryAuthAndRetryCallFunc = async () => {
      if (direct) {
        throw new EApiUnauthorizedException()
      } else {
        await this.#doUnauthorizedEvent(url)
        // повторим запрос ???
        return this.call(url, data, method, true)
      }
    }

    try {
      const { data: resp } = await this.#doAxios(url, data, method, config)

      if (resp.answer === 'ok') {
        return resp.data
      } else {
        // обработка внутренних ошибок - статусов
        if (resp.statusCode === 401) {
          return retryAuthAndRetryCallFunc()
        } else if (resp.statusCode === 409) {
          throw new EApiConflictException(resp.statusCode, resp.message)
        } else throw new EApiException(resp.statusCode, resp.message)
      }
    } catch (err) {
      // обработка транспортных ошибок HTTP
      if (err.response?.status == 400) {
        throw new EApiBadRequestException(err.response?.data?.message)
      } else if (err.response?.status == 401) {
        return retryAuthAndRetryCallFunc()
      } else if (err.response?.status == 403) {
        throw new EApiForbiddenException()
      } else if (err.response?.status == 409) {
        throw new EApiConflictException()
      } else if (err.response?.status == 429) {
        throw new EApiTooManyRequestsException()
      } else if ([500, 503].includes(err.response?.status)) {
        throw new EApiInternalException(err.response.status)
      } else if (err.response?.status) {
        throw new EApiException(err.response?.status, err)
      } else throw err
    }
  }

  static async checkServer() {
    try {
      const { data } = await this.#doAxios()
      if (data.answer === 'ok') {
        console.info(data.data)
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }
}

/* для совместимости со старыми проектами */
export const apiSetAccessToken = (...arg) => Api.setAccessToken(...arg)
export const apiSetUnauthorizedEvent = (...arg) => Api.unauthorizedEvent(...arg)
export const apiCall = (...arg) => Api.call(...arg)
export const checkApiServer = (...arg) => Api.checkServer(...arg)

/* default */
export default apiCall
