import { render, staticRenderFns } from "./MainMenu.vue?vue&type=template&id=46d67156&scoped=true&"
import script from "./MainMenu.vue?vue&type=script&lang=js&"
export * from "./MainMenu.vue?vue&type=script&lang=js&"
import style0 from "./MainMenu.vue?vue&type=style&index=0&id=46d67156&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d67156",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VProgressLinear})
