import apiCall from '@/lib/axiosApi'

const state = {
  reports: {},
}

const getters = {
  usersReports: state => state.reports,
}

const mutations = {
  SET_REPORTS(state, reports) {
    state.reports = reports
  },
}

const actions = {
  async GET_REPORTS({ commit /*, state*/ }, params) {
    try {
      // if (state.reports && Object.keys(state.reports).length) return
      const { year, quarter } = params

      let data = []
      if (quarter) {
        data = await apiCall(`plp/users`, { year, quarter }, 'POST')
      } else {
        data = await apiCall(`plp/users`, { year }, 'POST')
      }

      commit('SET_REPORTS', data)

      return data
    } catch (error) {
      commit('SET_ERROR', { head: 'Ошибка получения отчёта', error })
      return false
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
