import apiCall from '@/lib/axiosApi'
import { Api } from '@/lib/axiosApi'

const state = {
  plpDeadlines: null,
}

const getters = {
  plpDeadlines: state => state.plpDeadlines || [],
}

const mutations = {
  SET_PLP_DEADLINES(state, p_dt) {
    state.plpDeadlines = p_dt
  },
}

const actions = {
  GET_PLP_DEADLINES: async ({ commit, getters }, plp_id) => {
    const plpDeadlines = getters.plpDeadlines
    if (
      plpDeadlines.length > 0 &&
      plpDeadlines.findIndex(item => item.plp_id === plp_id) > -1
    )
      return plpDeadlines
    try {
      const data = await apiCall(`/plp-deadlines/get-by-plp/${plp_id}`)
      commit('SET_PLP_DEADLINES', data)

      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Сроки',
        text: 'Ошибка загрузки типов сроков',
        error,
      })
      throw error
    }
  },
  SET_PLP_DEADLINES: async ({ commit }, deadlines) => {
    try {
      const data = await Api.patch(`/plp-deadlines`, deadlines)
      commit('SET_PLP_DEADLINES', data)
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Сроки',
        text: 'Ошибка обновления сроков для данной экспертизы',
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
