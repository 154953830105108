import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/es5/locale/ru'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken2,
        secondary: colors.blueGrey,
        additional: colors.blueGrey.darken2,
        accent: colors.cyan,
        success: colors.green.darken1,
        warning: colors.orange.darken1,
      },
    },
  },
})
