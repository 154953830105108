import router from '@/router'
import { Api } from '@/lib/axiosApi'
import { ADMIN, CONSEQUENCES, EXPERT, FOREIGN } from '../const/userRoles'
import ls from '@/lib/localStorageUtils'

import { create } from 'vue-modal-dialogs'
import LoginViewVue from '../../views/LoginView.vue'

const cUserParam = 'user'
const cTokenParam = 'Token'

const state = {
  token: ls.loadDictVersion(cUserParam, '', cTokenParam),
  user: ls.loadDictDataDef(cUserParam, {}, '', cTokenParam),
  authenticating: false,
}

Api.setAccessToken(state.token)
//Api.setApiVersion('1.0')

var loginDlg = create(LoginViewVue, 'closeble', 'show-cancel')

function setUnauthorizedEvent() {
  Api.unauthorizedEvent(async () => {
    console.log('Hi Unauthorized men, go to Login please')

    const result = await loginDlg(true, false)
    if (!result) throw new Error('Финита ля комедия')

    // commit('setHasUnsavedChanges', false)
    // commit('logout')

    // router
    //   .push({
    //     name: 'Login',
    //     query: { redirect: router?.currentRoute?.fullPath || '/' },
    //   })
    //   .catch(() => {})
  })
}
function clearUnauthorizedEvent() {
  Api.unauthorizedEvent(null)
}

// setInterval(() => {
//   const token = ls.loadDictVersion(cUserParam, '', cTokenParam)
//   if (state.token !== token) {
//     state.token = token
//     Api.setAccessToken(token)
//     console.log('Api.setAccessToken', token)
//   }
// }, 5000)

const getters = {
  //флаг залогиненного пользователя
  isLoggedIn: state => !!state.token,
  isAuthenticating: state => !!state.authenticating,
  //имя пользователя
  userLogin: state => state.user?.email,
  //имя пользователя
  userName: state => state.user?.fio,
  // ID
  userID: state => state.user?.id,
  //имя роли пользователя - поле name из таблицы expertise_types
  userRole: state => state.user?.role?.name || 'guest',
  //название роли пользователя - поле title из таблицы expertise_types
  userRoleTitle: state => state.user?.role?.title || 'Ананимус',
  // userRoleData: (state, getters) => {
  //   return getters.userRoles.find(obj => obj.id === state.user.role.id) || []
  // },

  isAdmin: (state, getters) =>
    getters.userRole === ADMIN && getters.userID === 1,
  isManager: (state, getters) => getters.userRole === ADMIN,
  isExpert: (state, getters) => getters.userRole === EXPERT,
  isCuratorConsequences: (state, getters) => getters.userRole === CONSEQUENCES,
  isCuratorForeign: (state, getters) => getters.userRole === FOREIGN,
  isCurator: (state, getters) =>
    getters.isCuratorConsequences || getters.isCuratorForeign,
  isIndicationsEditor: (state, getters) =>
    getters.isAdmin || (state.user?.can_manage_dict_gnvlp ?? false),
}

const mutations = {
  authenticated(state, val) {
    state.authenticating = val
  },
  set_current_role(state, user) {
    if (state.user.id === user.id) {
      state.user.role_id = user.role.id
    }
  },
  auth_success(state, { token, user }) {
    state.token = token
    state.user = user
    Api.setAccessToken(token)

    ls.saveDict(cUserParam, token, user, true, '', cTokenParam)
    setUnauthorizedEvent()
  },
  logout(state) {
    state.token = ''
    state.user = {}
    Api.setAccessToken()
    ls.clearDict(cUserParam, '', cTokenParam)
    clearUnauthorizedEvent()
  },
}

const actions = {
  async check_auth({ commit, dispatch, getters, state }) {
    if (getters.isAuthenticating || !getters.isLoggedIn) return
    try {
      commit('authenticated', true)
      Api.setAccessToken(state.token)
      const ax = await Api.get('auth/authenticated')

      if (!ax.authenticated && state.token) {
        throw new Error('Ошибка авторизации')
      }
      commit('auth_success', { token: state.token, user: ax.user })
      if (getters.isLoggedIn) dispatch('INIT')
    } catch (e) {
      dispatch('logout')
      //throw new Error('Ошибка авторизации')
    } finally {
      commit('authenticated', false)
    }
  },

  async login({ commit, dispatch }, creds) {
    try {
      const { access_token, user } = await Api.post('auth/login', creds, {
        direct: true,
      })
      commit('auth_success', { token: access_token, user })

      dispatch('INIT')
    } catch (err) {
      commit('SET_ERROR', { head: 'Ошибка авторизации', err })
      throw new Error('Ошибка авторизации')
    }
  },
  async logout({ commit, getters }, redirectNeeded = true) {
    if (!getters.isLoggedIn) return
    // не ждём потверждения
    Api.get('auth/logout', {
      direct: true,
    }).catch(() => {})

    commit('logout')
    if (redirectNeeded) {
      const redirect = router.currentRoute.fullPath ?? undefined
      await router.push({ name: 'Login', query: { redirect } }).catch(() => {})
    }
  },
  async generateResetPasswordToken({ commit }, userId) {
    try {
      let url = `users/${userId}/generate-reset-password-token`
      // возвращаем теперь весь объект user, у него там и ФИО и токен сброса
      return await Api.post(url)
    } catch (err) {
      commit('SET_ERROR', {
        head: 'Ошибка генерации токена сброса пароля',
        err,
      })
      throw new Error('Ошибка генерации токена сброса пароля')
    }
  },
  async getUserInfo({ commit }, token) {
    try {
      let url = `auth/get-user-info/${token}`
      let response = await Api.get(url)
      return response.email
    } catch (err) {
      commit('SET_ERROR', { head: 'Ошибка проверки токена', err })
      throw new Error('Ошибка проверки токена')
    }
  },
  async resetPassword({ commit }, { token, newPassword }) {
    try {
      let url = `auth/reset-password/${token}`
      return await Api.post(url, { password: newPassword })
    } catch (err) {
      commit('SET_ERROR', { head: 'Ошибка создания нового пароля', err })
      throw new Error('Ошибка создания нового пароля')
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
