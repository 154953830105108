const state = {
  filterWidget: {},
}

const getters = {
  filterWidget: state => state.filterWidget,
}

const mutations = {
  show_panel(state, group) {
    state.filterWidget[group].panel = true
  },
  hide_panel(state, group) {
    state.filterWidget[group].panel = false
  },
  filter_exist(state, group) {
    state.filterWidget[group].exist = true
  },
  filter_not_exist(state, group) {
    state.filterWidget[group].exist = false
  },
  filter_remove(state, group) {
    state.filterWidget[group].remove = true
  },
  filter_removed(state, group) {
    state.filterWidget[group].remove = false
  },
  filter_avaiable(state, group) {
    state.filterWidget[group].avaiable = true
  },
  filter_register(state, group) {
    if (state.filterWidget[group]) return

    state.filterWidget[group] = {
      panel: false,
      exist: false,
      remove: false,
      avaiable: false,
    }
  },
}

const actions = {
  showFilterPanel({ commit }, group) {
    commit('show_panel', group)
  },
  hideFilterPanel({ commit }, group) {
    commit('hide_panel', group)
  },
  isFilterExist({ commit }, group) {
    commit('filter_exist', group)
  },
  isFilterNotExist({ commit }, group) {
    commit('filter_not_exist', group)
  },
  doRemoveFilter({ commit }, group) {
    commit('filter_remove', group)
  },
  resetFilter({ commit }, group) {
    commit('filter_removed', group)
  },
  setAvaiabledFilter({ commit }, group) {
    commit('filter_avaiable', group)
  },
  registerFilter({ commit }, group) {
    commit('filter_register', group)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
