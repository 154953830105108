import Vue from 'vue'
import Vuex from 'vuex'
import { Api } from '@/lib/axiosApi'
import login from '@/store/modules/login'
import usersStore from '@/store/modules/usersStore'
import references from '@/store/modules/references'
import storeMedicine from '@/store/modules/medicine'
import plp from '@/store/modules/plp'
import plpCurator from '@/store/modules/plpCurator'
import dictCriteria from '@/store/modules/dictCriteria'
import userReports from '@/store/modules/userReports'
import filterWidget from '@/store/modules/filterWidget'
import dictMedicine from '@/store/modules/dictMedicine'
import dictIndications from '@/store/modules/dictIndications'
import dictMKB10 from '@/store/modules/dictMKB10'
import dictSummary from '@/store/modules/dictSummary'
import dictCr from '@/store/modules/dictCr'
import deadlineTypes from './modules/deadlineTypes'
import plpDeadlines from './modules/plpDeadlines'
import dictAth from './modules/dictAth'

Vue.use(Vuex)

const gStore = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    server_online: true,
    errors: [],
    drawer: false,
    compareColumnWidth: [33, 33, 33],
    hasUnsavedChanges: false,
    hasLoading: 0,
    hasLogin: false,
    hasTitleGone: false,
  },
  getters: {
    online: state => state.server_online,
    /** флаги для блокировки перехода по страницам (роутер) */
    hasLoginProceed: state => state.hasLogin,
    /** флаги для блокировки перехода по страницам (роутер) */
    hasUnsavedChanges: (state, getters) =>
      state.hasUnsavedChanges || getters.savingExp,
    /** Идёт глобальная загрузка */
    hasLoading: (state, getters) => !!state.hasLoading || getters.loadingExp,
    /** Признак что заголовок спрятался за экран */
    hasTitleGone: state => state.hasTitleGone,
    lastError: state =>
      state.errors ? state.errors[state.errors.length - 1] : '',
  },
  mutations: {
    setServerOnline(state, online) {
      state.server_online = !!online
    },
    setCompareColumnWidth(state, widths) {
      if (!Array.isArray(widths))
        throw new Error('compareColumnWidth should be Array')
      if (widths?.length === 2) {
        state.compareColumnWidth = [
          widths[0],
          widths[1] - widths[0],
          100 - widths[1],
        ]
      } else if (widths?.length === 3) {
        state.compareColumnWidth = [...widths]
      } else throw new Error('compareColumnWidth length should be 2 or 3')
    },
    setDrawer(state, payload) {
      state.drawer = payload
    },
    setHasUnsavedChanges(state, unsaved) {
      state.hasUnsavedChanges = !!unsaved
    },
    setHasLoading(state, st) {
      state.hasLoading += st ? 1 : -1
    },
    setLoginProceed(state, st) {
      state.hasLogin = !!st
    },
    setTitleGone(state, gone) {
      state.hasTitleGone = !!gone
    },

    /**
     * ИНИЦИАЛИЗИРУЕМ ПУЛЛ ОШИБОК
     * @param head - заголовок
     * @param text - сообщение ошибки
     * @param error - сама ошибка (можно err или e)
     */
    SET_ERROR(state, { head, text, error, err, e }) {
      const errObj = error ?? err ?? e ?? {}
      const errHead = `${head ? `[${head}]` : ''}`
      const errText = `${text ? ` ${text}` : ''}`

      const message = `${errHead}${errText} ${
        errObj?.response?.data?.message || errObj
      }`

      errObj.message = message

      // если не был в последним разе
      const itWas = state.errors?.[state.errors.length - 1]?.message === message
      if (!itWas) {
        state.errors.push(errObj)

        Vue.$toast.error(message, {
          showCloseButtonOnHover: true,
          timeout: 5000,
          icon: true,
          onClose: () => {
            gStore.commit('CLEAR_ERROR', message)
          },
        })
      }
    },
    CLEAR_ERROR(state, message) {
      let index = state.errors.length - 1
      if (message) {
        index = state.errors?.findIndex(obj => obj?.message === message)
      }
      if (index > -1) state.errors.splice(index, 1)
    },
  },
  actions: {
    SET_ERROR({ commit }, errObj) {
      commit('SET_ERROR', errObj)
    },
    /**
     * ИНИЦИАЛИЗИРУЕМ СПРАВОЧНИКИ
     * @param dispatch
     * @returns {Promise<void>}
     * @constructor
     */
    async INIT({ commit, dispatch }) {
      try {
        // грузим всё скопом
        await Promise.all([
          //dispatch('get_users'),
          //dispatch('get_roles'),
          dispatch('get_quarters'),
          dispatch('get_extypes'),
        ])
      } catch (error) {
        commit('SET_ERROR', {
          head: 'INIT',
          error,
        })
      }
    },

    async CHECK_API({ commit, dispatch }) {
      const state = await Api.checkServer()
      commit('setServerOnline', state)
      if (state) {
        dispatch('check_auth') // проверка авторизованы мы или нет
      } else {
        setTimeout(() => {
          dispatch('CHECK_API')
        }, 6000)
      }
    },

    // Скачивает файл по ссылке с сервера, берет авторизацию нашего юзера
    async DOWNLOAD_FILE({ commit }, { url, fileName }) {
      try {
        await Api.getFile(url, fileName)
      } catch (error) {
        commit('SET_ERROR', {
          head: 'DOWNLOAD_FILE',
          error,
        })
        throw error
      }
    },

    async CREATE_ACTION_LOG(_, { code, message, data }) {
      try {
        await Api.post(`actions-log`, {
          code,
          message,
          data,
        })
      } catch (error) {
        console.log(`Ошибка CREATE_ACTION_LOG`, code, message, data)
      }
    },
  },
  modules: {
    references,
    login,
    usersStore,
    storeMedicine,
    plp,
    plpCurator,
    dictCriteria,
    userReports,
    filterWidget,
    dictMedicine,
    dictIndications,
    dictMKB10,
    dictSummary,
    dictCr,
    dictAth,
    deadlineTypes,
    plpDeadlines,
  },
})

export default gStore
