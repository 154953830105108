import apiCall from '@/lib/axiosApi'

const state = {
  deadlineTypes: null,
}

const getters = {
  deadlineTypes: state => state.deadlineTypes || [],
}

const mutations = {
  SET_DEADLINE_TYPES(state, dt) {
    state.deadlineTypes = dt
  },
}

const actions = {
  GET_DEADLINE_TYPES: async ({ commit, getters }) => {
    if (getters.deadlineTypes?.length > 0) return getters.deadlineTypes
    try {
      const data = await apiCall(`/deadline-types`)

      commit('SET_DEADLINE_TYPES', data)
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Сроки',
        text: 'Ошибка загрузки типов сроков',
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
