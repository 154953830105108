import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import { shortFIO, highlight, capitalize, ru_ending, ntl } from '@/lib/strUtils'
import * as ModalDialogs from 'vue-modal-dialogs'

const toastOptions = {
  // You can set your default options here
  timeout: 2500,
}

Vue.filter('shortFIO', shortFIO)
Vue.filter('highlight', highlight)
Vue.filter('capitalize', capitalize)
Vue.filter('ru_ending', ru_ending)
Vue.filter('ntl', ntl)

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(Toast, toastOptions)
Vue.use(VueClipboard)
Vue.use(ModalDialogs) // No options

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
