<template>
  <v-dialog value="1" width="500" persistent style="z-index: 999">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title> Вход в программу </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-form @submit.prevent="submitHandler" :disabled="inactive" id="login">
          <v-text-field
            id="username"
            v-model.trim="username"
            label="Логин"
            required
            :error-messages="loginErrors"
          >
          </v-text-field>
          <v-text-field
            id="password"
            v-model.trim="password"
            label="Пароль"
            required
            :error-messages="passwordErrors"
            @click:append="show = !show"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
          >
          </v-text-field>

          <v-alert
            v-if="!online"
            type="warning"
            class="my-3"
            outlined
            icon="mdi-lan-disconnect"
          >
            Сервер не доступен
          </v-alert>

          <v-alert v-if="authError" type="error" class="my-3" outlined>
            Ошибка авторизации
          </v-alert>

          <div class="d-flex justify-space-between">
            <v-btn
              type="submit"
              :width="showCancelBtn ? '48%' : undefined"
              :block="!showCancelBtn"
              :loading="loading"
              :disabled="inactive"
              depressed
              color="primary"
              class="mt-3"
              >Войти</v-btn
            >
            <v-btn
              v-if="showCancelBtn"
              width="48%"
              class="mt-3"
              depressed
              @click="closeDlg(false)"
              >отмена</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Login-View',
  props: {
    closeble: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    username: { required },
    password: { required },
  },
  computed: {
    ...mapGetters(['online', 'userLogin']),
    loginErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required &&
        errors.push('Это поле не должно быть пустым')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required &&
        errors.push('Это поле не должно быть пустым')
      return errors
    },
    inactive() {
      return !this.online || this.loading
    },
    showCancelBtn() {
      return this.closeble && this.showCancel
    },
  },
  data() {
    return {
      username: '',
      password: '',
      show: false,
      authError: false,
      loading: false,
    }
  },
  mounted() {
    this.username = this.userLogin || ''
    this.setLoginProceed(true)
  },
  destroyed() {
    this.setLoginProceed(false)
  },
  methods: {
    ...mapActions(['login']),
    ...mapMutations(['setLoginProceed']),

    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const { username, password } = this
      this.loading = true
      this.authError = false
      try {
        await this.login({ username, password })
        this.closeDlg(true)
      } catch {
        this.authError = true
        this.loading = false
      }
    },

    closeDlg(result) {
      this.setLoginProceed(false)
      if (this.closeble) {
        this.$close && this.$close(!!result)
      } else {
        this.$router.push(this.$route?.query?.redirect || '/').catch(() => {})
      }
    },
  },
}
</script>
