<template>
  <v-app>
    <v-navigation-drawer
      app
      v-if="isLoggedIn"
      v-model="showMenu"
      width="340"
      style="font-size: 14px"
    >
      <template #prepend>
        <div class="logo-content mb-2 pointer" @click="goHome">
          <div class="ml-2">
            <v-img width="35" src="@/assets/cekkmp.svg"></v-img>
          </div>
          <div class="ml-3">ЦЭККМП : ПИКО</div>
          <!-- NewYear -->
          <santa-hat v-if="isNewYear" left="-18" top="-7" angle="25" />
          <!-- Valentin day -->
          <v-icon
            v-if="isValentinDay"
            color="red lighten-2"
            right
            large
            title="From Russia with ♥"
            >mdi-heart</v-icon
          >
          <!-- Womens day -->
          <womens-day v-if="isWomensDay" left="-22" top="-2" size="34" />
          <rose-svg v-if="isWomensDay" left="-262" top="-2" size="50" />
        </div>
      </template>
      <MainMenu />
    </v-navigation-drawer>

    <v-app-bar app dense>
      <v-app-bar-nav-icon
        v-if="isLoggedIn"
        @click="showMenu = !showMenu"
      ></v-app-bar-nav-icon>

      <v-app-bar-title class="text-button font-weight-regular text-truncate">
        <span v-if="storedPlpMNN" class="font-weight-medium"
          >{{ storedPlpMNN
          }}<span v-if="storedPlpYearQuarter" class="font-weight-light">
            ({{ storedPlpYearQuarter }})</span
          ></span
        >
        <span v-else
          ><span v-if="!isLoggedIn">ЦЭККМП : </span>Платформа Информатизации
          Комплексной Оценки</span
        >
      </v-app-bar-title>

      <v-spacer />
      <v-icon
        v-if="!online"
        color="warning"
        title="Отсутствует связь с сервером"
        >mdi-lan-disconnect</v-icon
      ><v-spacer />

      <div class="mr-4" v-if="isLoggedIn">
        <span class="font-weight-bold mr-1">{{ userRoleTitle }}:</span>
        <span>{{ userName }}</span>
      </div>

      <v-icon title="Выход" @click="logout" v-if="isLoggedIn"
        >mdi-exit-to-app</v-icon
      >
    </v-app-bar>

    <v-main>
      <new-year v-if="isNewYear" size="250" count="4" />
      <template v-if="isWomensDay">
        <rose-svg left="-5" top="-5" size="70" absolute angle="-5" />
        <rose-svg left="-10" top="-5" size="70" absolute angle="-15" />
        <rose-svg left="-12" top="-4" size="70" absolute angle="-30" />
      </template>
      <div class="pa-8">
        <router-view />
      </div>
    </v-main>

    <v-overlay v-if="loader" style="z-index: 999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- ModalDialogs -->
    <dialogs-wrapper />
  </v-app>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import { mapGetters, mapActions } from 'vuex'
import NewYear from '@/components/holidays/NewYear'
import SantaHat from '@/components/holidays/SantaHat'
import WomensDay from '@/components/holidays/WomensDay'
import RoseSvg from '@/components/holidays/RoseSvg'

export default {
  name: 'App',
  components: { MainMenu, NewYear, SantaHat, WomensDay, RoseSvg },
  mounted() {
    this.CHECK_API() // он же проверит авторизацию как только система будет онлайн
  },
  data: () => ({
    showMenu: true,
    width: 0,
  }),
  computed: {
    ...mapGetters([
      'online',
      'isLoggedIn',
      'userName',
      'userRole',
      'userRoleTitle',
      'loadingPlp',
      'storedPlpMNN',
      'storedPlpYearQuarter',
    ]),
    loader() {
      return false
    },
    isNewYear() {
      if (!this.isLoggedIn) return false

      const now = new Date()
      const year = now.getFullYear()
      const startNY = new Date(`${year}-12-20`)
      const endNY = new Date(`${year}-01-14`)

      return now >= startNY || now <= endNY
    },
    isValentinDay() {
      if (!this.isLoggedIn) return false
      const now = new Date()
      // если  14 - февраля
      return now.getMonth() === 1 && [13, 14, 15].includes(now.getDate())
    },
    isWomensDay() {
      if (!this.isLoggedIn) return false
      const now = new Date()
      // если  8 - марта
      return now.getMonth() === 2 && [6, 7, 8, 9].includes(now.getDate())
    },
  },
  methods: {
    ...mapActions(['logout', 'CHECK_API']),
    async goHome() {
      if (this.$router.currentRoute.fullPath !== '/')
        this.$router.push('/').catch()
    },
  },
}
</script>

<style scope>
@font-face {
  font-family: 'ErbaumBook';
  src: url('./assets/fonts/Erbaum-Book.eot');
  src: local('Erbaum Book'), local('Erbaum-Book'),
    url('./assets/fonts/Erbaum-Book.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Erbaum-Book.woff2') format('woff2'),
    url('./assets/fonts/Erbaum-Book.woff') format('woff'),
    url('./assets/fonts/Erbaum-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.logo-content {
  font-family: 'ErbaumBook', sans-serif !important;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 1.5em;
  height: 48px;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 45%,
    rgb(245, 245, 245) 66%
  );
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
  opacity: 0.6;
}
.tip:hover {
  opacity: 1;
}
/* Класс (цвет) подсветки в поиске */
.highlight {
  background-color: lightskyblue;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<style>
.red-line {
  text-indent: 1.5em;
}

.cursor-help {
  cursor: help;
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: calc(6px);
  height: calc(6px);
}

::-webkit-scrollbar-track {
  cursor: pointer !important;
  background: white;
}

::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  background: #c4c6c4;
  border-radius: calc(5px);
}
</style>
