import Vue from 'vue'
import apiCall from '@/lib/axiosApi'

const state = {
  users: null,
}

const prod = process.env.NODE_ENV === 'production'

const getters = {
  usersList: state => state.users ?? [],
}

const mutations = {
  set_users(state, users) {
    state.users = (users ?? []).filter(({ id }) => !prod || id > 1)
  },
  save_user(state, user) {
    const index = state.users.findIndex(obj => obj.id === user.id)
    Vue.set(state.users, index, user)
  },
  delete_user(state, id) {
    const index = state.users.findIndex(obj => obj.id === id)
    state.users.splice(index, 1)
  },
  add_user(state, user) {
    state.users.push(user)
  },
}

const actions = {
  async get_users({ getters, commit }, force) {
    // только для админа
    if (!getters.isManager) {
      console.warn('get_users - only for Admin')
      return
    }
    // второй раз не грузимся - только F5
    if (!force && getters.usersList.length) return

    try {
      const data = await apiCall('users')
      commit('set_users', data)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Пользователи',
        text: 'Ошибка получения пользователей',
        error,
      })
    }
  },
  async add_user({ commit, dispatch }, user) {
    try {
      const data = await apiCall('users', user, 'POST')
      commit('add_user', data)
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Пользователи',
        text: 'Ошибка добавления пользователя',
        error,
      })
      dispatch('get_users')
      throw new Error('Ошибка добавления пользователя')
    }
  },
  async delete_user({ commit, dispatch }, id) {
    try {
      await apiCall(`users/${id}`, undefined, 'DELETE')
      commit('delete_user', id)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Пользователи',
        text: 'Ошибка удаления пользователя',
        error,
      })
      dispatch('get_users')
      throw new Error('Ошибка удаления пользователя')
    }
  },
  async save_user({ commit, dispatch }, user) {
    try {
      const data = await apiCall(`users/${user.id}`, user, 'PATCH')
      commit('save_user', data)
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Пользователи',
        text: 'Ошибка сохранения пользователя',
        error,
      })
      dispatch('get_users')
      throw new Error('Ошибка сохранения пользователя')
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
