<template>
  <v-list dense nav class="pa-0 pb-5">
    <template v-for="(item, index) in mainRouters">
      <div v-if="isVisible(item.meta.role)" :key="index">
        <!-- главное меню без подменю -->
        <v-list-item
          v-if="!item.meta.hasMedicineMenu && !item.children"
          exact
          :to="{ name: item.name }"
          active-class="green lighten-3"
        >
          <v-list-item-icon class="mr-2">
            <v-icon>{{ item.meta.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ item.meta.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- главное меню с подменю -->
        <div v-else-if="!item.meta.hasMedicineMenu && item.children">
          <v-list-group color="text" :value="isOpenSub(item)">
            <template v-slot:activator
              ><v-list-item-icon class="mr-2">
                <v-icon>{{ item.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-body-1">{{
                item.meta.title
              }}</v-list-item-title>
            </template>
            <template v-for="(item, index) in item.children">
              <v-list-item
                v-if="isVisible(item.meta.role)"
                :to="{ name: item.name }"
                active-class="green lighten-4"
                exact
                :key="index"
              >
                <v-list-item-icon class="ml-6 mr-1">
                  <v-icon small>{{ item.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-2">
                    {{ item.meta.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </div>

        <!-- меню для plp = hasMedicineMenu -->
        <div v-else-if="hasMedicineMenu" :key="index">
          <!-- plp preview -->
          <v-list-item
            exact
            :to="{ name: item.name }"
            active-class="green lighten-4 font-weight-bold"
            :title="getTitle(item.meta.title)"
          >
            <v-list-item-icon class="mr-2">
              <v-icon color="primary">{{ item.meta.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-1 font-weight-bold">
                {{ getTitle(item.meta.title) || '...' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-progress-linear v-if="loadingPlp" indeterminate color="green" />

          <!-- меню экспертизы -->
          <template v-for="(item, index) in items">
            <div
              v-if="!loadingPlp && isVisible(item.role) && item.enabled"
              :key="index"
            >
              <v-list-item
                v-if="!item.sub"
                :to="{ name: item.name }"
                active-class="green lighten-4"
                exact
              >
                <v-list-item-icon
                  class="mx-3"
                  :style="`margin-right: ${
                    item.is_compare ? -4 : 12
                  }px !important`"
                >
                  <v-icon :color="getIconColor(item)">{{ item.icon }}</v-icon>
                  <v-icon
                    v-if="item.is_compare"
                    color="primary darken-3"
                    small
                    style="left: -10px; top: 8px; mar"
                    >mdi-vector-combine</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-2">
                    {{ item.caption }}
                  </v-list-item-title>
                </v-list-item-content>
                <!-- Иконки состояния экспертизы  -->
                <IconCompleted :value="item" />
              </v-list-item>
              <!-- Экспертиза с подменю -->
              <div v-else>
                <v-list-group
                  v-if="isSubExists(item)"
                  :value="item.enabled"
                  color="text"
                >
                  <template v-slot:activator
                    ><v-list-item-icon class="mx-3">
                      <v-icon :disabled="!item.enabled">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-body-2">{{
                      item.caption
                    }}</v-list-item-title>
                  </template>
                  <template v-for="(item, index) in item.sub">
                    <v-list-item
                      v-if="isVisible(item.role) && item.enabled"
                      :to="{ name: item.name }"
                      active-class="green lighten-4"
                      exact
                      :key="index"
                    >
                      <v-list-item-icon class="ml-6 mr-1">
                        <v-icon small :color="getIconColor(item)">{{
                          item.icon
                        }}</v-icon>
                        <v-icon
                          v-if="item.is_compare"
                          color="primary darken-3"
                          x-small
                          style="left: -6px; top: 8px"
                          >mdi-vector-combine</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.caption }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <!-- Иконки состояния экспертизы  -->
                      <IconCompleted :value="item" small />
                    </v-list-item>
                  </template>
                </v-list-group>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import IconCompleted from './IconCompleted.vue'

export default {
  components: { IconCompleted },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    ...mapGetters([
      'userRole',
      'isManager',
      'storedPlpId',
      'storedPlpMNN',
      'storedMyTasks',
      'storedPlpTasks',
      'loadingPlp',
    ]),
    hasMedicineMenu() {
      return this.$route.meta.hasMedicineMenu
    },
    isAnalogSelected() {
      return this.$route.path.includes('analogs')
    },
    mainRouters() {
      return this.$router.options.routes.filter(route => route.meta?.mainMenu)
    },
  },
  watch: {
    // при проекта меняем меню MedicineView
    storedPlpId: 'initMedicineRouters',
    storedPlpTasks: 'initMedicineRouters',
  },
  created() {
    this.initMedicineRouters()
  },
  methods: {
    isVisible(roles) {
      return !roles || roles.includes(this.userRole)
    },
    isOpenSub(item) {
      return this.$route.path.startsWith(item.path) ? 1 : 0
    },
    isSubExists(item) {
      return !!item?.sub?.find(el => this.isVisible(el.role) && el.enabled)
    },
    getTitle(title) {
      if (typeof title === 'function') return title()
      return title
    },

    initMedicineRouters() {
      if (this.storedPlpId) {
        const r = this.$router.options.routes.find(
          r => r.name === 'MedicineView'
        )
        this.items = r ? this.getMedicineRoutes(r.children) : []
      } else this.items = []
    },

    findMyTask(ex_type) {
      if (this.storedMyTasks)
        return this.storedMyTasks.find(
          itm => itm.expertiseType?.name === ex_type
        )
      return null
    },

    findCompareTasks(ex_type) {
      if (this.storedPlpTasks)
        return this.storedPlpTasks.filter(
          itm => itm.expertiseType?.name === ex_type
        )
      return null
    },

    getIconColor({ is_compare, is_expertise }) {
      if (is_expertise) return 'success darken-2'
      if (is_compare) return 'primary lighten-3'
    },

    getMedicineRoutes(routes) {
      if (!routes) return null
      const result = []

      for (let route of routes) {
        if (route.meta?.hasMedicineMenu) {
          let sub = this.getMedicineRoutes(route.children)

          const task = this.findMyTask(route.meta?.ex_type)
          const is_expertise = !!route.meta?.ex_type && !route.meta?.comparePage
          const is_compare = route.meta?.comparePage

          const item = {
            name: route.name,
            path: route.path,
            caption: route.meta?.caption,
            icon: route.meta?.icon ?? 'mdi-square-small',
            role: route.meta?.role,
            enabled: !route.meta?.ex_type || !!task?.id || is_compare,
            is_expertise,
            is_analog_completed:
              route.meta?.is_analog &&
              task?.is_analog_completed &&
              !task?.is_completed,
            is_completed: task?.is_completed,
            is_checked: task?.is_checked,
            sub,
          }
          if (is_compare) {
            const [task_1, task_2] = this.findCompareTasks(route.meta?.ex_type)
            const is_completed_1 = task_1?.is_completed
            const is_completed_2 = task_2?.is_completed
            const is_completed =
              (is_completed_1 && is_completed_2) ||
              // лтбо если нет одного из юзеров  и второй готов
              (!task_1?.user_id ^ !task_2?.user_id &&
                (is_completed_1 || is_completed_2))

            const is_analog_completed_1 =
              route.meta?.is_analog &&
              !is_completed &&
              task_1?.is_analog_completed
            const is_analog_completed_2 =
              route.meta?.is_analog &&
              !is_completed &&
              task_2?.is_analog_completed
            const is_analog_completed =
              !task_1?.user_id ^ !task_2?.user_id &&
              (is_analog_completed_1 || is_analog_completed_2)

            result.push({
              ...item,
              is_compare,
              is_completed,
              is_completed_1,
              is_completed_2,
              // аналоги
              is_analog_completed,
              is_analog_completed_1,
              is_analog_completed_2,
            })
          } else result.push(item)
        }
      }

      return result
    },
  },
}
</script>

<style scoped>
.menu-item {
  display: block;
  padding: 8px;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.87);
}
.menu-item.level-0 {
  padding-left: 8px;
}
.menu-item.level-1 {
  padding-left: 16px;
}
.menu-item.level-2 {
  /*padding-left: 24px;*/
  padding-left: 45px;
}
.menu-item.expanded {
  font-weight: bold;
  border-bottom: none;
}
.menu-item:hover:not(.expanded):not(.router-link-active) {
  /*background-color: #f5f5f5;*/
  background-color: #e6ee9c;
}
/* default vue-router class */
.router-link-active {
  /*background-color: #f5f5f5;*/
  background-color: #a5d6a7;
  cursor: default;
}
.router-link-green:not(.router-link-active) {
  background-color: #e8f5e9;
}
</style>
