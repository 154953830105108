const SAMECHAR = {
  А: 'A',
  Б: '6',
  В: 'B',
  Г: 'G',
  Д: 'D',
  Е: 'E',
  Ё: 'E',
  З: '3',
  И: 'N',
  Й: 'N',
  К: 'K',
  М: 'M',
  Н: 'H',
  О: 'O',
  Р: 'P',
  С: 'C',
  Т: 'T',
  У: 'Y',
  Х: 'X',
  Ч: '4',
  Я: 'R',
}
/** Конвертируем строку со случайными русскими символами в латиницу */
export function lat_norm(text) {
  const result = [...text.toUpperCase()].map(c => SAMECHAR[c] ?? c).join('')
  return result
}

/** Делаем заглавной первую букву  */
export function capitalize(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/** Формат отображения валюты с разделителями и запятой (округление до сотых, два знака после запятой)
 * @param {number} value - значение
 * @param {boolean} [onlyDigits=false] - только цифры илаче денежный формат с символом рубля
 */
export function toMoney(value, onlyDigits = false) {
  const formatter = new Intl.NumberFormat(
    'ru-RU',
    onlyDigits
      ? {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : {
          style: 'currency',
          currency: 'RUB',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
  )
  return formatter.format(Number(value || 0))
}

/** окончание по числительному  */
export function ru_ending(num, words) {
  if (!Array.isArray(words) || !Number.isInteger(num)) return num

  //russian case cast for quantities, like: 1 ведро, 42 ведра, 27 ведер...
  if (num > 10 && num < 20) return `${num} ${words[2] ?? words[1]}`
  const rem = num % 10
  if (rem === 0 || (rem >= 5 && rem <= 9))
    return `${num} ${words[2] ?? words[1]}` // несколько
  if (rem >= 2 && rem <= 4) return `${num} ${words[1]}` // два
  return `${num} ${words[0]}` // одно
}

/** из Иванов Пётр Сергеевич делаем Иванов П.С. */
export function shortFIO(fullFIO) {
  // если пусто то пусто
  if (!fullFIO || typeof fullFIO !== 'string') return fullFIO

  const fio = fullFIO.trim().split(' ')
  // если просто Администратор, или без отчества - выходим отсюда
  // выйдем если пришло Фамилия И.О., Фамилия Имя
  if (fio.length <= 2) return fullFIO

  const patr = capitalize(fio.pop().charAt(0))
  const name = capitalize(fio.pop().charAt(0))
  const surname = capitalize(fio.join('-'))

  return `${surname} ${name}.${patr}.`
}

// экранируем спецсимволы
function escapeRegex(string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

/** Подсветка поиска (как фильтр) */
export function highlight(value, query) {
  if (!query || !value) return value
  function convert(part) {
    return "<span class='highlight'>" + part + '</span>'
  }
  try {
    return value.replace(new RegExp(escapeRegex(query), 'ig'), convert)
  } catch {
    return value
  }
}

/** NumberToLocal
 * Число в локальной строку с разделителями
 * @param {Number} value
 * @param {String} defValue
 * @returns строка с в локализованном фомате ( разделитель тысячных долей )
 */
export function ntl(value, defValue = '0') {
  return typeof value === 'number' ? Number(value).toLocaleString() : defValue
}

/** searchByWords - поиск без учёта пунктуации
 *
 */
export function searchByWords(value, search) {
  if (!search) return true
  // сравниваем без учёта пунктуации и регистронезависимо
  const strs = search.split(/[\s,.;:\\/()]/g).filter(s => !!s)
  const tmp = String(value).toLocaleLowerCase()
  let i
  for (const st of strs) {
    i = tmp.indexOf(st.toLocaleLowerCase(), i)
    if (i < 0) return false
    i += st.length
  }
  return true
}

// const r = searchByWords(
//   'таблетки, покрытые пленочной оболочкой',
//   'таблетки, оболочкой'
// )
// console.log(r)
