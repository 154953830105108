import apiCall from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'

const dictGvs = 'gvsDict'
const dictOrg = 'orgDict'

const state = {
  // Info
  dictGvsInfo: ls.loadDictVersion(dictGvs, {}),
  dictGvsVersion: ls.load(dictGvs) || '',
  dictOrgInfo: ls.loadDictVersion(dictOrg, {}),
  dictOrgVersion: ls.load(dictOrg) || '',
  // СПРАВОЧНИК
  dictGvs: null,
  dictOrg: null,
}

const getters = {
  dictGvsVersion: state => state.dictGvsVersion,
  dictGvsDescription: (_, getters) => getters.dictGvsInfo.description ?? '...',
  dictGvsInfo: state => state.dictGvsInfo || {},
  dictGvs: state => state.dictGvs || [],

  // dictOrgVersion: (_, getters) => getters.dictOrgInfo.version ?? '',
  dictOrgVersion: state => state.dictOrgVersion,
  dictOrgDescription: (_, getters) => getters.dictOrgInfo.description ?? '...',
  dictOrgInfo: state => state.dictOrgInfo || {},
  dictOrg: state => state.dictOrg || [],
}

const mutations = {
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_GVS_DICT_LOCAL(state) {
    try {
      state.dictGvsVersion = ls.load(dictGvs, {})
      state.dictGvs = ls.loadDictData(dictGvs)
    } catch {
      state.dictGvsInfo = {}
      state.dictGvs = null
    }
  },
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_ORG_DICT_LOCAL(state) {
    try {
      state.dictOrgVersion = ls.load(dictOrg, {})
      state.dictOrg = ls.loadDictData(dictOrg)
    } catch {
      state.dictOrgInfo = {}
      state.dictOrg = null
    }
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_GVS_DICT(state, { version, description, data }) {
    state.dictGvsInfo = { version, description }
    state.dictGvs = data
    ls.saveDict(dictGvs, { version, description }, data)
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_ORG_DICT(state, { version, description, data }) {
    state.dictOrgInfo = { version, description }
    state.dictOrg = data
    ls.saveDict(dictOrg, { version, description }, data)
  },
}

const actions = {
  /**  ПОЛУЧАЕМ СПРАВОЧНИК ЛП ДЛЯ ГВС, СРАВНИВАЕМ ВЕРСИИ */
  GET_GVS_DICT: async ({ commit, getters }) => {
    const currentVersion = getters.dictGvsVersion
    try {
      const { version, description, data } = await apiCall(
        `dict/gvs?version=${currentVersion}`
      )
      if (data) {
        commit('SET_GVS_DICT', {
          version,
          description,
          data,
        })
      } else {
        commit('GET_GVS_DICT_LOCAL')
      }
      return getters.dictGvs
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник ГВС',
        text: 'Ошибка загрузки справочника ГВС',
        error,
      })
      throw error
    }
  },
  /**  ПОЛУЧАЕМ СПРАВОЧНИК ЛП ДЛЯ ЭО, СРАВНИВАЕМ ВЕРСИИ */
  GET_ORG_DICT: async ({ commit, getters }) => {
    const currentVersion = getters.dictOrgVersion
    try {
      const { version, description, data } = await apiCall(
        `dict/exp-org?version=${currentVersion}`
      )
      if (data) {
        commit('SET_ORG_DICT', {
          version,
          description,
          data,
        })
      } else {
        commit('GET_ORG_DICT_LOCAL')
      }
      return getters.dictOrg
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник ЭО',
        text: 'Ошибка загрузки справочника ЭО',
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
