import Vue from 'vue'
import apiCall from '@/lib/axiosApi'

const state = {
  storedMedicine: {},
  storedMedicineID: null,
  storedMedicineSigners: null,
  storedMedicines: null,
}

const getters = {
  storedMedicine: state => state.storedMedicine,
  storedMedicineID: state => state.storedMedicineID,
  storedMedicineSigners: state => state.storedMedicineSigners,
  storedMedicines: state => state.storedMedicines,
}

const mutations = {
  SET_MEDICINES: (state, items) => {
    state.storedMedicines = []
    const med = items.map(
      ({ expertise_year, expertise_quarter, mnn, ...item }) => ({
        ...item,
        year: expertise_year,
        quarter: expertise_quarter,
        name: mnn,
      })
    )

    state.storedMedicines = med
  },
  SET_MEDICINE: (state, { id, data }) => {
    if (state.storedMedicineID !== id) {
      state.storedMedicineSigners = null
    }
    state.storedMedicine = data
    state.storedMedicineID = id
  },
  SET_MEDICINE_SIGNERS(state, { id, data }) {
    if (state.storedMedicineID !== id) {
      state.storedMedicine = null
    }
    state.storedMedicineSigners = data
    state.storedMedicineID = id
  },

  DELETE_MEDICINE(state, id) {
    const index = state.storedMedicines?.findIndex(obj => obj.id === id)
    if (index > -1) {
      state.storedMedicines.splice(index, 1)
    }
  },
  SET_VALUE: (state, item) => {
    const lvl = item.field.split('.')
    if (lvl.length > 1) {
      state.storedMedicine[lvl[0]][lvl[1]] = item.value
    } else {
      state.storedMedicine[lvl[0]] = item.value
    }
  },
  UPDATE_SETUP: (
    state,
    { expertise_year, expertise_quarter, mnn, ...data }
  ) => {
    const index = state.storedMedicines?.findIndex(plp => plp.id === data.id)
    // нужно глянуть почему пропадает строка
    if (index > -1) {
      Vue.set(state.storedMedicines, index, {
        ...state.storedMedicines[index],
        ...data,
        year: expertise_year,
        quarter: expertise_quarter,
        name: mnn,
      })
    }
  },
}

const actions = {
  SAVE_SETUP: async ({ commit, dispatch }, object) => {
    try {
      const data = await apiCall('plp-tasks/mass-update', object, 'POST')
      commit('UPDATE_SETUP', data)
      // обновить проект - перегрузить
      dispatch('RELOAD_PLP')
    } catch (error) {
      commit('SET_ERROR', {
        head: 'PLP',
        text: 'Ошибка сохранения настроек документа',
        error,
      })
      throw error
    }
  },

  GET_MEDICINES: async (
    { commit, dispatch, getters },
    { year, quarter } = {}
  ) => {
    const data = await apiCall(
      `plp?addTasks${year ? `&year=${year}` : ''}${
        quarter ? `&quarter=${quarter}` : ''
      }`
    )
    commit('SET_MEDICINES', data)

    if (getters.isCurator) {
      // подчистить кэш для уже закрытых экспертиз для куратора
      data?.forEach(({ id: plp_id, tasks }) => {
        const expSet = new Set()
        tasks?.forEach(({ is_checked, expertiseType }) => {
          if (is_checked) expSet.add(expertiseType.name)
        })
        expSet.forEach(ex_type =>
          dispatch('COMPARE_CLEAR_PLP', { plp_id, ex_type })
        )
      })
    }
  },

  SAVE_DATA: async ({ commit, state }) => {
    try {
      const data = await apiCall(
        `plp/${state.storedMedicineID}`,
        {
          data: state.storedMedicine,
        },
        'PATCH'
      )
      commit('SET_MEDICINE', data)
    } catch (error) {
      throw new Error('[SAVE_DATA] ' + error)
    }
  },

  GET_MEDICINE: async ({ commit }, id) => {
    const data = await apiCall(`plp/${id}?addData`)
    commit('SET_MEDICINE', data)
  },

  async GET_MEDICINE_SIGNERS({ commit, getters }, id) {
    try {
      if (id === getters.storedMedicineID && getters.storedMedicineSigners)
        return

      commit('SET_MEDICINE_SIGNERS', { data: null, id })

      const data = await apiCall(`users/get-signers-for-plp/${id}`)
      commit('SET_MEDICINE_SIGNERS', { data, id })
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Ошибка получения списка подписантов',
        error,
      })
      throw new Error('Ошибка получения списка подписантов')
    }
  },

  SET_VALUE: ({ commit }, { field, value }) => {
    commit('SET_VALUE', { field, value })
  },

  SEND_MEDICINE_TO_MZ: async ({ commit, dispatch }, { id, was_sent_to_mz }) => {
    if (!id) return
    try {
      const plp = await apiCall(
        `plp/${was_sent_to_mz ? 'send' : 'revoke'}/${id}`,
        undefined,
        'POST'
      )
      commit('UPDATE_SETUP', plp)
      // dispatch('GET_MEDICINES') // год квартал?
    } catch (error) {
      commit('SET_ERROR', {
        head: 'PLP',
        text: `Ошибка ${
          was_sent_to_mz ? 'отправки ЛП в' : 'возврата ЛП из'
        } МЗ`,
        error,
      })
      dispatch('GET_MEDICINES')
      throw error
    }
  },

  DELETE_MEDICINE: async ({ commit }, id) => {
    if (!id) return
    try {
      await apiCall(`plp/${id}`, undefined, 'DELETE')
      commit('DELETE_MEDICINE', id)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'PLP',
        text: 'Ошибка удаления ЛП',
        error,
      })
      // dispatch('GET_MEDICINES')
      throw new Error('Ошибка удаления ЛП')
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
