import apiCall from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'

const KEI = 'Kei'
const AVB = 'Avb'
const KEI_ADD = 'AddKei'
const AVB_ADD = 'AddAvb'
const cCOMPRESS = true

const storedTemplateVersion = method => `stored${method}Version`
const storedTemplate = method => `stored${method}Template`
const storedCriteriaVersion = method => `stored${method}CriteriaVersion`
const storedCriteria = method => `stored${method}Criteria`

const state = {
  storedAvbVersion: ls.load(storedTemplateVersion(AVB)) || '',
  storedAvbCriteriaVersion: ls.load(storedCriteriaVersion(AVB)) || '',
  storedAvbTemplate: null,
  storedAvbCriteria: null,

  storedKeiVersion: ls.load(storedTemplateVersion(KEI)) || '',
  storedKeiCriteriaVersion: ls.load(storedCriteriaVersion(KEI)) || '',
  storedKeiTemplate: null,
  storedKeiCriteria: null,

  storedAddKeiVersion: ls.load(storedTemplateVersion(KEI_ADD)) || '',
  storedAddKeiCriteriaVersion: ls.load(storedCriteriaVersion(KEI_ADD)) || '',
  storedAddKeiTemplate: null,
  storedAddKeiCriteria: null,

  storedAddAvbVersion: ls.load(storedTemplateVersion(AVB_ADD)) || '',
  storedAddAvbCriteriaVersion: ls.load(storedCriteriaVersion(AVB_ADD)) || '',
  storedAddAvbTemplate: null,
  storedAddAvbCriteria: null,

  storedAnswersData: null,

  // лоадер загрузки ответов (бегунок в таблице)
  loadingAnswers: false,

  // поля для настроек
  criterion: {
    id: 0,
    correspond: false,
    tabIndex: 0,
    tagsSelected: [],
  },
}

const getters = {
  // СОСТОЯНИЕ ЗАГРУЗКИ
  loadingAnswers: state => state.loadingAnswers,
  // МАССИВ ОТВЕТОВ
  storedAnswersData: state => state.storedAnswersData ?? [],
  // ВЕРСИИ АВБ/КЕИ ШАБЛОНОВ
  storedAvbVersion: state => state.storedAvbVersion,
  storedKeiVersion: state => state.storedKeiVersion,
  storedAddKeiVersion: state => state.storedAddKeiVersion,
  storedAddAvbVersion: state => state.storedAddAvbVersion,
  // ВЕРСИИ КРИТЕРИЕВ
  storedKeiCriteriaVersion: state => state.storedKeiCriteriaVersion,
  storedAvbCriteriaVersion: state => state.storedAvbCriteriaVersion,
  storedAddKeiCriteriaVersion: state => state.storedAddKeiCriteriaVersion,
  storedAddAvbCriteriaVersion: state => state.storedAddAvbCriteriaVersion,
  // СПИСОК АВБ/КЭИ ШАБЛОНОВ
  storedAvbTemplate: state => state.storedAvbTemplate,
  storedKeiTemplate: state => state.storedKeiTemplate,
  storedAddKeiTemplate: state => state.storedAddKeiTemplate,
  storedAddAvbTemplate: state => state.storedAddAvbTemplate,
  // СПИСОК АВБ/КЭИ критериев
  storedAvbCriteria: state => state.storedAvbCriteria,
  storedKeiCriteria: state => state.storedKeiCriteria,
  storedAddKeiCriteria: state => state.storedAddKeiCriteria,
  storedAddAvbCriteria: state => state.storedAddAvbCriteria,

  /** ПОЛУЧАЮ АВБ ШАБЛОНЫ ДЛЯ ТЕКУЩЕГО КРИТЕРИЯ */
  getAvbTemplate: (state, getters) => {
    return getters.storedAvbTemplate?.filter(
      i =>
        i.is_matched === state.criterion.correspond &&
        i.dict_criterion_id === state.criterion.id
    )
  },
  /** ПОЛУЧАЮ КЭИ ШАБЛОНЫ ДЛЯ ТЕКУЩЕГО КРИТЕРИЯ */
  getKeiTemplate: (state, getters) => {
    return getters.storedKeiTemplate?.filter(
      i =>
        i.is_matched === state.criterion.correspond &&
        i.dict_criterion_id === state.criterion.id
    )
  },
  /** ПОЛУЧАЮ КЭИ ДОПОЛНИТЕЛЬНЫЕ ШАБЛОНЫ ДЛЯ ТЕКУЩЕГО КРИТЕРИЯ */
  getAddKeiTemplate: (state, getters) => {
    return getters.storedAddKeiTemplate?.filter(
      i => i.dict_criterion_id === state.criterion.id
    )
  },
}
//ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ ФУНКЦИЙ

/** УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ШАБЛОНОВ СПРАВОЧНИКА + ВЕРСИИ в стор и локал */
function setStateLocal(state, method, { version, data }) {
  const vers_field = storedTemplateVersion(method)
  const template_field = storedTemplate(method)

  state[vers_field] = version
  state[template_field] = data
  console.log('setStateLocal - trySaveVersObj')
  ls.trySaveVersObj(vers_field, version, template_field, data, cCOMPRESS)
}

/** УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ КРИТЕРИЕВ СПРАВОЧНИКА + ВЕРСИИ в стор и локал */
function setCriteriaStateLocal(state, method, { version, data }) {
  const vers_field = storedCriteriaVersion(method)
  const criteria_field = storedCriteria(method)

  state[vers_field] = version
  state[criteria_field] = data
  console.log('setCriteriaStateLocal - trySaveVersObj')
  ls.trySaveVersObj(vers_field, version, criteria_field, data, cCOMPRESS)
}

/** ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ ШАБЛОНОВ ДЛЯ СПРАВОЧНИКОВ */
function getStateFromLocal(state, method) {
  const vers_field = storedTemplateVersion(method)
  const template_field = storedTemplate(method)
  if (!state[template_field])
    try {
      state[vers_field] = ls.load(vers_field)
      state[template_field] = ls.loadObj(template_field)
    } catch (error) {
      state.state[vers_field] = ''
      state.state[template_field] = []
      ls.clear(vers_field)
      ls.clearObj(template_field)
    }
}

/** ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ КРИТЕРИЕВ ДЛЯ СПРАВОЧНИКОВ */
function getCriteriaStateFromLocal(state, method) {
  const vers_field = storedCriteriaVersion(method)
  const criteria_field = storedCriteria(method)

  if (!state[criteria_field])
    try {
      state[vers_field] = ls.load(vers_field)
      state[criteria_field] = ls.loadObj(criteria_field)
    } catch (error) {
      state.state[vers_field] = ''
      state.state[criteria_field] = []
      ls.clear(vers_field)
      ls.clearObj(criteria_field)
    }
}

const mutations = {
  SET_LOADING_ANSWER_DATA(state, stat) {
    state.loadingAnswers = !!stat
  },
  SET_ANSWERS_DATA(state, list) {
    state.storedAnswersData = [...list]
  },

  SET_AVB_CRITERIA(state, { version, data }) {
    setCriteriaStateLocal(state, AVB, { version, data })
  },
  /** УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ ДЛЯ СПРАВОЧНИКА АВБ */
  SET_AVB_TEMPLATE(state, { version, data }) {
    setStateLocal(state, AVB, { version, data })
  },
  /** ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ ДЛЯ СПРАВОЧНИКА АВБ */
  SET_AVB_TEMPLATE_LOCAL(state) {
    getStateFromLocal(state, AVB)
  },
  SET_KEI_CRITERIA(state, { version, data }) {
    setCriteriaStateLocal(state, KEI, { version, data })
  },
  SET_KEI_CRITERIA_LOCAL(state) {
    getCriteriaStateFromLocal(state, KEI)
  },
  SET_AVB_CRITERIA_LOCAL(state) {
    getCriteriaStateFromLocal(state, AVB)
  },
  /** УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ ДЛЯ СПРАВОЧНИКА КЕИ */
  SET_KEI_TEMPLATE(state, { version, data }) {
    setStateLocal(state, KEI, { version, data })
  },
  /** ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ ДЛЯ СПРАВОЧНИКА КЭИ */
  SET_KEI_TEMPLATE_LOCAL(state) {
    getStateFromLocal(state, KEI)
  },
  SET_ADD_KEI_CRITERIA(state, { version, data }) {
    setCriteriaStateLocal(state, KEI_ADD, { version, data })
  },
  SET_ADD_AVB_CRITERIA(state, { version, data }) {
    setCriteriaStateLocal(state, AVB_ADD, { version, data })
  },
  SET_ADD_KEI_CRITERIA_LOCAL(state) {
    getCriteriaStateFromLocal(state, KEI_ADD)
  },
  SET_ADD_AVB_CRITERIA_LOCAL(state) {
    getCriteriaStateFromLocal(state, AVB_ADD)
  },
  /** УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ ДЛЯ ДОПОЛНИТЕЛЬНОГО СПРАВОЧНИКА КЕИ */
  SET_ADD_KEI_TEMPLATE(state, { version, data }) {
    setStateLocal(state, KEI_ADD, { version, data })
  },
  SET_ADD_AVB_TEMPLATE(state, { version, data }) {
    setStateLocal(state, AVB_ADD, { version, data })
  },
  /** ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ ДЛЯ ДОПОЛНИТЕЛЬНОГО СПРАВОЧНИКА КЭИ */
  SET_ADD_KEI_TEMPLATE_LOCAL(state) {
    getStateFromLocal(state, KEI_ADD)
  },
  SET_ADD_AVB_TEMPLATE_LOCAL(state) {
    getStateFromLocal(state, AVB_ADD)
  },

  SET_TEMPLATE_CRITERION(state, { id, correspond, tagsSelected }) {
    state.criterion = { id, correspond, tagsSelected }
  },
}

const actions = {
  /** ЗАГРУЖАЕМ ОТВЕТЫ ДЛЯ ВЫБРАННОГО КРИТЕРИЯ И ТЕГОВ */
  LOAD_ANSWERS: async ({ commit }, preparedData = {}) => {
    try {
      commit('SET_LOADING_ANSWER_DATA', true)
      commit('SET_ANSWERS_DATA', [])
      const data = await apiCall(
        `dict/answers/get-by-filter`,
        preparedData,
        'POST'
      )
      commit('SET_ANSWERS_DATA', data)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник КЭИ/АВБ',
        text: `Ошибка загрузки ответов ${preparedData.dict_criterion_id}`,
        error,
      })
      throw error
    } finally {
      commit('SET_LOADING_ANSWER_DATA', false)
    }
  },
  /** ЗАГРУЖАЕМ КРИТЕРИИ АВБ */
  LOAD_AVB_CRITERIA: async ({ commit, getters }) => {
    const currentVersion = getters.storedAvbCriteriaVersion
    try {
      const { version, data } = await apiCall(
        `dict/criteria/avb?version=${currentVersion}`
      )
      if (data) {
        commit('SET_AVB_CRITERIA', { version, data })
      } else {
        commit('SET_AVB_CRITERIA_LOCAL')
      }
      return getters.storedAvbCriteria
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник АВБ',
        text: 'Ошибка загрузки критериев АВБ',
        error,
      })
      throw error
    }
  },
  /** ЗАГРУЖАЕМ КРИТЕРИИ КЭИ */
  LOAD_KEI_CRITERIA: async ({ commit, getters }) => {
    const currentVersion = getters.storedKeiCriteriaVersion
    try {
      const { version, data } = await apiCall(
        `dict/criteria/kei?version=${currentVersion}`
      )
      if (data) {
        commit('SET_KEI_CRITERIA', { version, data })
      } else {
        commit('SET_KEI_CRITERIA_LOCAL')
      }
      return getters.storedKeiCriteria
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник КЭИ',
        text: 'Ошибка загрузки критериев КЭИ',
        error,
      })
      throw error
    }
  },
  /** ЗАГРУЖАЕМ ДОП. КРИТЕРИИ КЭИ */
  LOAD_ADD_KEI_CRITERIA: async ({ commit, getters }) => {
    const currentVersion = getters.storedAddKeiCriteriaVersion
    try {
      const { version, data } = await apiCall(
        `dict/criteria/kei-add?version=${currentVersion}`
      )
      if (data) {
        commit('SET_ADD_KEI_CRITERIA', { version, data })
      } else {
        commit('SET_ADD_KEI_CRITERIA_LOCAL')
      }
      return getters.storedAddKeiCriteria
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник  доп. КЭИ',
        text: 'Ошибка загрузки критериев доп. КЭИ',
        error,
      })
      throw error
    }
  },
  /** ЗАГРУЖАЕМ ДОП. КРИТЕРИИ AVB */
  LOAD_ADD_AVB_CRITERIA: async ({ commit, getters }) => {
    const currentVersion = getters.storedAddAvbCriteriaVersion
    try {
      const { version, data } = await apiCall(
        `dict/criteria/avb-add?version=${currentVersion}`
      )
      if (data) {
        commit('SET_ADD_AVB_CRITERIA', { version, data })
      } else {
        commit('SET_ADD_AVB_CRITERIA_LOCAL')
      }
      return getters.storedAddAvbCriteria
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник  доп. АВБ',
        text: 'Ошибка загрузки критериев доп. АВБ',
        error,
      })
      throw error
    }
  },
  /** ЗАГРУЖАЕМ СПРАВОЧНИК ШАБЛОНОВ ДЛЯ АВБ */
  LOAD_AVB_TEMPLATE: async ({ commit, getters }) => {
    const currentVersion = getters.storedAvbVersion
    try {
      const { version, data } = await apiCall(
        `dict/templates/avb?version=${currentVersion}`
      )
      if (data) {
        commit('SET_AVB_TEMPLATE', { version, data })
      } else {
        commit('SET_AVB_TEMPLATE_LOCAL')
      }
      return getters.storedAvbTemplate
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочника АВБ',
        text: 'Ошибка загрузки справочника АВБ',
        error,
      })
      throw error
    }
  },
  /** ПОЛУЧАЕМ СПРАВОЧНИК ШАБЛОНОВ И СРАВНИВАЕМ ВЕРСИИ ДЛЯ КЕИ... */
  LOAD_KEI_TEMPLATE: async ({ commit, getters }) => {
    const currentVersion = getters.storedKeiVersion
    try {
      const { version, data } = await apiCall(
        `dict/templates/kei?version=${currentVersion}`
      )
      if (data) {
        commit('SET_KEI_TEMPLATE', { version, data })
      } else {
        commit('SET_KEI_TEMPLATE_LOCAL')
      }
      return getters.storedKeiTemplate
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочника КЕИ',
        text: 'Ошибка загрузки справочника КЕИ',
        error,
      })
      throw error
    }
  },

  /** ПОЛУЧАЕМ ДОПОЛНИТЕЛЬНЫЙ СПРАВОЧНИК ШАБЛОНОВ И СРАВНИВАЕМ ВЕРСИИ ДЛЯ КЕИ... */
  LOAD_ADD_KEI_TEMPLATE: async ({ commit, getters }) => {
    const currentVersion = getters.storedAddKeiVersion
    try {
      const { version, data } = await apiCall(
        `dict/templates/kei-add?version=${currentVersion}`
      )
      if (data) {
        commit('SET_ADD_KEI_TEMPLATE', { version, data })
      } else {
        commit('SET_ADD_KEI_TEMPLATE_LOCAL')
      }
      return getters.storedAddKeiTemplate
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Дополнительного справочника КЕИ',
        text: 'Ошибка загрузки дополнительного справочника КЕИ',
        error,
      })
      throw error
    }
  },
  /** ПОЛУЧАЕМ ДОПОЛНИТЕЛЬНЫЙ СПРАВОЧНИК ШАБЛОНОВ И СРАВНИВАЕМ ВЕРСИИ ДЛЯ AVB... */
  LOAD_ADD_AVB_TEMPLATE: async ({ commit, getters }) => {
    const currentVersion = getters.storedAddAvbVersion
    try {
      const { version, data } = await apiCall(
        `dict/templates/avb-add?version=${currentVersion}`
      )
      if (data) {
        commit('SET_ADD_AVB_TEMPLATE', { version, data })
      } else {
        commit('SET_ADD_AVB_TEMPLATE_LOCAL')
      }
      return getters.storedAddAvbTemplate
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Дополнительного справочника АВБ',
        text: 'Ошибка загрузки дополнительного справочника АВБ',
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
