import { Api } from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'
import apiCall from '@/lib/axiosApi'

const dictName = 'dictAth'

const state = {
  // Info
  dictAthInfo: ls.loadDictVersion(dictName, {}),
  // СПРАВОЧНИК МКБ
  dictAthData: null,
}

const getters = {
  dictAthVersion: (_, getters) => getters.dictAthInfo.version,
  dictAthDescription: (_, getters) => getters.dictAthInfo.description,
  dictAthInfo: state => state.dictAthInfo || {},
  dictAthData: state => state.dictAthData || [],
}

const mutations = {
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_ATH_LOCAL(state) {
    if (!state.dictAthData)
      try {
        state.dictAthInfo = ls.loadDictVersion(dictName, {})
        state.dictAthData = ls.loadDictData(dictName)
      } catch (error) {
        state.dictAthInfo = {}
        state.dictAthData = null
      }
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_ATH_DICT(state, { version, description, data }) {
    state.dictAthInfo = { version, description }
    state.dictAthData = data
    ls.saveDict(dictName, { version, description }, data)
  },
}

const actions = {
  /**
   * ПОЛУЧАЕМ СТПРАВОЧНИК И СВРАВНИВАЕМ ВЕРСИИ...
   */
  GET_ATH_DICT: async ({ commit, getters }) => {
    const currentVersion = getters.dictAthVersion
    try {
      const { version, description, data } = await Api.get(
        `dict/ath?version=${currentVersion || ''}`
      )
      if (data) {
        // всем в корне сделаем признак root = true
        data.forEach(elem => {
          elem.root = true
        })
        commit('SET_ATH_DICT', { version, description, data })
      } else {
        commit('GET_ATH_LOCAL')
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник ATH',
        text: 'Ошибка загрузки справочника ATH',
        error,
      })
      throw error
    }
  },
  IMPORT_ATH_DICT: async ({ commit, dispatch }) => {
    try {
      const { messages } = await Api.post(`dict/ath/nsi/import`)
      if (!messages?.[0]) {
        commit('SET_ATH_DICT', {})
        await dispatch('GET_ATH_DICT')
      }
      return messages?.[0] || 'Справочник ATH обновлён успешно!'
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник ATH',
        text: 'Ошибка обновления справочника ATH',
        error,
      })
      throw error
    }
  },
  async CHECK_ATH_EXISTS(_, ath) {
    const url = `dict/ath/code/${ath}`
    return await apiCall(url)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
