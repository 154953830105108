<template>
  <div class="new-year" :style="`left: ${left}px; top: ${top}px`">
    <svg
      :width="`${size}px`"
      :height="`${size}px`"
      viewBox="-7.43 -7.43 90 90"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      stroke="#000000"
      stroke-width="0.0007425"
      :transform="`matrix(1, 0, 0, 1, 0, 0)rotate(${angle})`"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_iconCarrier">
        <g id="_6" data-name="6" transform="translate(-562.336 -261.58)">
          <path
            id="Path_39"
            data-name="Path 39"
            d="M572.624,311.75l16.162-42.11a8.254,8.254,0,0,1,7.72-5.31h3.3c3.158,0,4.908,1.7,6.84,3.63l.01.02,14.99,16.28a4.154,4.154,0,0,1,.66,4.66l-.29.6-.45.89a4.139,4.139,0,0,1-4.11,2.29l-1.1-.1,7.361,19.151Z"
            fill="#ee6159"
          ></path>
          <path
            id="Path_40"
            data-name="Path 40"
            d="M623.737,314.5a.118.118,0,0,1-.019,0H572.624a2.748,2.748,0,0,1-2.567-3.735l16.162-42.11a11.066,11.066,0,0,1,10.287-7.075h3.3c4.349,0,6.809,2.46,8.785,4.436a2.663,2.663,0,0,1,.2.223L623.67,282.4a6.951,6.951,0,0,1,1.1,7.753l-.744,1.5a6.919,6.919,0,0,1-3.781,3.39l5.931,15.429a2.753,2.753,0,0,1-2.434,4.033ZM576.625,309h43.09l-5.925-15.414a2.749,2.749,0,0,1,2.814-3.726l1.1.1a1.47,1.47,0,0,0,.158.009,1.4,1.4,0,0,0,1.239-.778l.729-1.469a1.408,1.408,0,0,0-.215-1.609l-14.982-16.271c-.019-.021-.038-.042-.056-.064-1.78-1.774-2.837-2.7-4.771-2.7h-3.3a5.538,5.538,0,0,0-5.151,3.54Z"
            fill="#151515"
          ></path>
          <g id="Group_18" data-name="Group 18">
            <circle
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="12.012"
              cy="12.012"
              r="12.012"
              transform="translate(609.811 277.524)"
              fill="#ffffff"
            ></circle>
            <path
              id="Path_41"
              data-name="Path 41"
              d="M621.821,304.3a14.771,14.771,0,1,1,4.715-.776A14.752,14.752,0,0,1,621.821,304.3Zm0-24.03a9.264,9.264,0,1,0,8.776,6.307A9.252,9.252,0,0,0,621.824,280.271Z"
              fill="#151515"
            ></path>
          </g>
          <g id="Group_19" data-name="Group 19">
            <line
              id="Line_12"
              data-name="Line 12"
              x2="7.627"
              y2="0.753"
              transform="translate(602.454 291.312)"
              fill="#ec534e"
            ></line>
            <path
              id="Path_42"
              data-name="Path 42"
              d="M610.084,294.815c-.09,0-.182,0-.273-.013l-7.627-.753a2.75,2.75,0,0,1,.541-5.474l7.627.754a2.75,2.75,0,0,1-.268,5.486Z"
              fill="#151515"
            ></path>
          </g>
          <g id="Group_20" data-name="Group 20">
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="66.17"
              height="21.25"
              rx="5.53"
              transform="translate(565.086 311.75)"
              fill="#ffffff"
            ></rect>
            <path
              id="Path_43"
              data-name="Path 43"
              d="M625.726,335.75h-55.11a8.29,8.29,0,0,1-8.28-8.28V317.28a8.29,8.29,0,0,1,8.28-8.28h55.11a8.29,8.29,0,0,1,8.28,8.28v10.19A8.29,8.29,0,0,1,625.726,335.75Zm-55.11-21.25a2.783,2.783,0,0,0-2.78,2.78v10.19a2.783,2.783,0,0,0,2.78,2.78h55.11a2.783,2.783,0,0,0,2.78-2.78V317.28a2.783,2.783,0,0,0-2.78-2.78Z"
              fill="#151515"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SantaHat',
  props: {
    left: {
      default: 0,
    },
    top: {
      default: 0,
    },
    angle: {
      default: 0,
    },
    size: {
      default: 30,
    },
  },
}
</script>

<style scoped>
.new-year {
  position: relative;
  pointer-events: none;
}
</style>
