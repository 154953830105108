import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { ADMIN, EXPERT, CONSEQUENCES, FOREIGN } from '@/store/const/userRoles'
import {
  EX_EXPERTISE,
  EX_FOREIGN,
  SUB_EX_ABOUT,
  SUB_EX_ANALOG,
  SUB_EX_KEI,
  SUB_EX_AVB,
  SUB_EX_OTHER,
  SUB_EX_AGE,
  EX_CONCLUSION,
  SUB_EX_CONCLUSION,
  EX_SUMMARY,
  SUB_EX_SUMMARY,
  EX_PRICING,
  SUB_EX_PRICING,
  EX_COST,
  SUB_EX_COST,
  SUB_EX_KEI_MOD,
  SUB_EX_ASSESSMENT,
  SUB_EX_AVB_MOD,
  EX_MAIN_EXPS,
} from '@/store/const/expertise'
import { EX_MODIFICATION } from '../store/const/expertise'

VueRouter.prototype.absUrl = function (url, newTab = true) {
  const link = document.createElement('a')
  link.href = url
  link.target = newTab ? '_blank' : ''
  if (newTab) link.rel = 'noopener noreferrer'
  link.click()
}

Vue.use(VueRouter)

const ROUTE_LOGIN = 'Login'

const roleCanExpertise = [ADMIN, EXPERT, FOREIGN, CONSEQUENCES]

const roleCanReport = [ADMIN, EXPERT, FOREIGN, CONSEQUENCES]
const roleCanConclusionReport = [ADMIN, EXPERT, FOREIGN, CONSEQUENCES]

const routes = [
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/UsersPage.vue'),
    meta: {
      mainMenu: true,
      requireAuth: true,
      role: [ADMIN],
      title: 'Пользователи',
      icon: 'mdi-account-multiple',
    },
  },
  {
    path: '/upload',
    name: 'MedicinesUploadView',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/MedicinesUpload'),
    meta: {
      requireAuth: true,
      role: [ADMIN],
      title: 'Загрузка PLP',
      icon: 'mdi-human-dolly',
    },
  },
  {
    path: '/',
    name: 'WorksDistribution',
    alias: ['/medicinepage', '/workdistribution', '/userassigment'],
    component: () =>
      import(/* webpackChunkName: "main" */ '@/views/WorksDistribution.vue'),
    meta: {
      mainMenu: true,
      requireAuth: true,
      title: 'Лекарственные препараты',
      icon: 'mdi-pill',
    },
  },
  // роуты экспертизы PLP
  {
    path: '/medicine-:id',
    name: 'MedicineView',
    component: () =>
      import(/* webpackChunkName: "main" */ '@/views/MedicineView'),
    meta: {
      mainMenu: true,
      hasMedicineMenu: true,
      requireAuth: true,
      caption: 'Просмотр PLP',
      title: () => store.getters.storedPlpMNN,
      previewPage: true,
      icon: 'mdi-chevron-right-circle-outline',
    },
    children: [
      {
        path: 'settings',
        name: 'Settings',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/views/MedicineSettings.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          title: 'Настройка (эксперты / сроки / подписант)',
          caption: 'Настройка проекта / сроки',
          role: [ADMIN],
          icon: 'mdi-cog-outline',
        },
      },
      {
        path: 'deadlines',
        name: 'Deadlines',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/views/ReferencesDeadlines.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          title: 'Сроки',
          caption: 'Сроки',
          role: [EXPERT, FOREIGN, CONSEQUENCES],
          icon: 'mdi-clipboard-text-clock-outline',
        },
      },
      {
        path: 'about',
        name: 'About',
        component: () =>
          import(
            /* webpackChunkName: "main-expertise" */ '@/views/MedicineAbout.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Информация о препарате',
          role: roleCanExpertise,
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_ABOUT,
          icon: 'mdi-information-outline',
          next: {
            name: 'Analog',
            label: 'Перейти к Аналогам',
            title: 'Перейти к следующей экспертизе Аналоги',
          },
        },
      },
      {
        path: 'compare-about',
        name: 'CompareAbout',
        component: () =>
          import(
            /* webpackChunkName: "main-curator" */ '@/views/CompareAbout.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Сравнить информацию о препарате',
          role: [ADMIN, CONSEQUENCES],
          comparePage: true,
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_ABOUT,
          icon: 'mdi-information-outline',
          next: {
            name: 'CompareAnalogList',
            label: 'Перейти к сравнению Аналогов',
            title: 'Перейти к следующей экспертизе сравнение Аналогов',
          },
        },
      },
      {
        path: 'analogs',
        name: 'Analog',
        component: () =>
          import(
            /* webpackChunkName: "main-expertise" */ '@/views/MedicineAnalog.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Аналоги',
          role: roleCanExpertise,
          icon: 'mdi-ab-testing',
          is_analog: true,
        },
        children: [
          {
            path: 'cr',
            name: 'Analog-CR',
            component: () =>
              import(
                /* webpackChunkName: "main-expertise" */ '@/views/MedicineAnalogsRecommendations'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Клинические Рекомендации',
              role: roleCanExpertise,
              ex_type: EX_EXPERTISE,
              ex_type_sub: SUB_EX_ANALOG,
              icon: 'mdi-bookshelf',
              next: {
                name: 'Analog-List',
                label: 'К списку аналогов',
              },
              is_analog: true,
            },
          },
          {
            path: 'list',
            name: 'Analog-List',
            component: () =>
              import(
                /* webpackChunkName: "main-expertise" */ '@/views/MedicineAnalogsList.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Перечень аналогов',
              role: roleCanExpertise,
              ex_type: EX_EXPERTISE,
              ex_type_sub: SUB_EX_ANALOG,
              icon: 'mdi-playlist-star',
              next: {
                name: 'Analog-data',
                label: 'К данным по аналогам',
              },
              is_analog: true,
            },
          },
          {
            path: 'data',
            name: 'Analog-data',
            component: () =>
              import(
                /* webpackChunkName: "main-expertise" */ '@/views/MedicineAnalogsInformation.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Данные об аналогах',
              role: roleCanExpertise,
              ex_type: EX_EXPERTISE,
              ex_type_sub: SUB_EX_ANALOG,
              icon: 'mdi-briefcase-eye-outline',
              next: {
                name: 'Kei',
                label: 'Перейти к КЭИ',
                title: 'Перейти к следующей экспертизе КЭИ',
              },
              is_analog: true,
            },
          },
          {
            path: 'compare-analog-list',
            name: 'CompareAnalogList',
            component: () =>
              import(
                /* webpackChunkName: "main-curator" */ '@/views/CompareAnalogList'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Сравнить аналоги',
              role: [ADMIN, CONSEQUENCES],
              comparePage: true,
              ex_type: EX_EXPERTISE,
              ex_type_sub: SUB_EX_ANALOG,
              icon: 'mdi-playlist-star',
              next: {
                name: 'CompareExpertiseKEI',
                label: 'Перейти к сравнению КЭИ',
                title: 'Перейти к следующей экспертизе сравнение КЭИ',
              },
              is_analog: true,
            },
          },
        ],
        redirect: {
          name: 'Analog-CR',
        },
      },
      {
        path: 'keis',
        name: 'Keis',
        component: () =>
          import(
            /* webpackChunkName: "main-summary" */ '@/views/ExpertisesKei.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'КЭИ',
          role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
          icon: 'mdi-alpha-k',
        },
        children: [
          {
            path: 'kei',
            name: 'Kei',
            component: () =>
              import(
                /* webpackChunkName: "main-expertise" */ '@/views/MedicineKEI.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Экспертиза КЭИ',
              role: roleCanExpertise,
              ex_type: EX_MAIN_EXPS,
              ex_type_sub: SUB_EX_KEI,
              icon: 'mdi-alpha-k-box-outline',
              next: {
                name: 'Avb',
                label: 'Перейти к АВБ',
                title: 'Перейти к следующей экспертизе АВБ',
              },
            },
          },
          {
            path: 'compare-expertise-kei',
            name: 'CompareExpertiseKEI',
            component: () =>
              import(
                /* webpackChunkName: "main-curator" */ '@/views/CompareExpertiseKA'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Сравнить экспертизы КЭИ',
              role: [ADMIN, CONSEQUENCES],
              comparePage: true,
              ex_type: EX_MAIN_EXPS,
              ex_type_sub: SUB_EX_KEI,
              icon: 'mdi-alpha-k-box-outline',
              next: {
                name: 'CompareExpertiseAVB',
                label: 'Перейти к сравнению АВБ',
                title: 'Перейти к следующей экспертизе сравнение АВБ',
              },
            },
          },
          {
            path: 'kei-mod',
            name: 'KeiMod',
            component: () =>
              import(
                /* webpackChunkName: "main-expertise" */ '@/views/MedicineModKei.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'доработка КЭИ',
              role: roleCanExpertise,
              ex_type: EX_MODIFICATION,
              ex_type_sub: SUB_EX_KEI_MOD,
              icon: 'mdi-alpha-k-box',
              next: {
                name: 'AvbMod',
                label: 'Перейти к доработке АВБ',
                title: 'Перейти к следующей экспертизе доработка АВБ',
              },
            },
          },
          {
            path: 'compare-expertise-kei-mod',
            name: 'CompareExpertiseKEIMod',
            component: () =>
              import(
                /* webpackChunkName: "main-curator" */ '@/views/CompareExpertiseKAMod.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Сравнение доработки КЭИ',
              role: [ADMIN, CONSEQUENCES],
              comparePage: true,
              ex_type: EX_MODIFICATION,
              ex_type_sub: SUB_EX_KEI_MOD,
              icon: 'mdi-alpha-k-box',
              next: {
                name: 'CompareExpertiseAVB',
                label: 'Перейти к АВБ',
                title: 'Перейти к следующей экспертизе сравнение АВБ',
              },
            },
          },
        ],
      },
      {
        path: 'avbs',
        name: 'Avbs',
        component: () =>
          import(
            /* webpackChunkName: "main-summary" */ '@/views/ExpertisesAvb.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'АВБ',
          role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
          icon: 'mdi-alpha-a',
        },
        children: [
          {
            path: 'avb',
            name: 'Avb',
            component: () =>
              import(
                /* webpackChunkName: "main-expertise" */ '@/views/MedicineAVB.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Экспертиза АВБ',
              role: roleCanExpertise,
              ex_type: EX_MAIN_EXPS,
              ex_type_sub: SUB_EX_AVB,
              icon: 'mdi-alpha-a-box-outline',
            },
          },
          {
            path: 'compare-expertise-avb',
            name: 'CompareExpertiseAVB',
            component: () =>
              import(
                /* webpackChunkName: "main-curator" */ '@/views/CompareExpertiseKA'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Сравнить экспертизы АВБ',
              role: [ADMIN, CONSEQUENCES],
              comparePage: true,
              ex_type: EX_MAIN_EXPS,
              ex_type_sub: SUB_EX_AVB,
              icon: 'mdi-alpha-a-box-outline',
            },
          },
          {
            path: 'avb-mod',
            name: 'AvbMod',
            component: () =>
              import(
                /* webpackChunkName: "main-expertise" */ '@/views/MedicineModAvb.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'доработка АВБ',
              role: roleCanExpertise,
              ex_type: EX_MODIFICATION,
              ex_type_sub: SUB_EX_AVB_MOD,
              icon: 'mdi-alpha-a-box',
            },
          },
          {
            path: 'compare-expertise-avb-mod',
            name: 'CompareExpertiseAVBMod',
            component: () =>
              import(
                /* webpackChunkName: "main-curator" */ '@/views/CompareExpertiseKAMod.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Сравнение доработки АВБ',
              role: [ADMIN, CONSEQUENCES],
              comparePage: true,
              ex_type: EX_MODIFICATION,
              ex_type_sub: SUB_EX_AVB_MOD,
              icon: 'mdi-alpha-a-box',
            },
          },
        ],
      },
      {
        path: 'other',
        name: 'Other',
        component: () =>
          import(
            /* webpackChunkName: "main-expertise" */ '@/views/MedicineOther.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Прочие данные',
          role: roleCanExpertise,
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_OTHER,
          icon: 'mdi-order-bool-ascending-variant',
        },
      },
      {
        path: 'compare-other',
        name: 'CompareOther',
        component: () =>
          import(
            /* webpackChunkName: "main-curator" */ '@/views/CompareOther.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Сравнить прочие данные',
          role: [ADMIN, CONSEQUENCES],
          comparePage: true,
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_OTHER,
          icon: 'mdi-order-bool-ascending-variant',
        },
      },
      {
        path: 'econ-assessment',
        name: 'EconAssessment',
        component: () =>
          import(
            /* webpackChunkName: "main-agency" */ '@/views/MedicineAssessment.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Оценка ЭП',
          role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_ASSESSMENT,
          icon: 'mdi-account-multiple-outline',
        },
      },
      {
        path: 'compare-assessment',
        name: 'CompareAssessment',
        component: () =>
          import(
            /* webpackChunkName: "main-curator" */ '@/views/CompareAssessment.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Сравнить оценку ЭП',
          role: [ADMIN, CONSEQUENCES],
          comparePage: true,
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_ASSESSMENT,
          icon: 'mdi-account-group-outline',
        },
      },
      {
        path: 'agencies',
        name: 'Agencies',
        component: () =>
          import(
            /* webpackChunkName: "main-agency" */ '@/views/MedicineAgencies'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Агентства',
          role: roleCanExpertise,
          ex_type: EX_FOREIGN,
          ex_type_sub: SUB_EX_AGE,
          icon: 'mdi-web',
        },
      },
      {
        path: 'compare-agencies',
        name: 'CompareAgencies',
        component: () =>
          import(
            /* webpackChunkName: "main-curator_foreign" */ '@/views/CompareAgencies.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Сравнить агентства',
          role: [ADMIN, FOREIGN],
          comparePage: true,
          ex_type: EX_FOREIGN,
          ex_type_sub: SUB_EX_AGE,
          icon: 'mdi-web',
        },
      },
      {
        path: 'summary',
        name: 'Summary',
        component: () =>
          import(
            /* webpackChunkName: "main-summary" */ '@/views/MedicineSummary.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Представление о ЛП',
          role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
          icon: 'mdi-presentation',
        },
        children: [
          {
            path: 'info',
            name: 'Summary-Info',
            component: () =>
              import(
                /* webpackChunkName: "main-summary" */ '@/views/MedicineSummaryInfo.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Общая информация об ЛП',
              role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
              ex_type: EX_SUMMARY,
              ex_type_sub: SUB_EX_SUMMARY,
              icon: 'mdi-information-variant',
              next: {
                name: 'Summary-Assessment',
                label: 'К данным по закупке',
              },
            },
          },
          {
            path: 'assessment',
            name: 'Summary-Assessment',
            component: () =>
              import(
                /* webpackChunkName: "main-summary" */ '@/views/MedicineSummaryPurchase.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Данные по закупке',
              role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
              ex_type: EX_SUMMARY,
              ex_type_sub: SUB_EX_SUMMARY,
              icon: 'mdi-cash-multiple',
              next: {
                name: 'Summary-score',
                label: 'К оценке экспертной организаций',
              },
            },
          },
          {
            path: 'score',
            name: 'Summary-score',
            component: () =>
              import(
                /* webpackChunkName: "main-summary" */ '@/views/MedicineSummaryScore.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Оценка экспертной организаций',
              role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
              ex_type: EX_SUMMARY,
              ex_type_sub: SUB_EX_SUMMARY,
              icon: 'mdi-account-school',
              next: {
                name: 'Summary-gvs',
                label: 'К оценке ГВС',
              },
            },
          },
          {
            path: 'gvs',
            name: 'Summary-gvs',
            component: () =>
              import(
                /* webpackChunkName: "main-summary" */ '@/views/MedicineSummaryGVS.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Оценка ГВС',
              role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
              ex_type: EX_SUMMARY,
              ex_type_sub: SUB_EX_SUMMARY,
              icon: 'mdi-account-group',
              next: {
                name: 'Summary-additional',
                label: 'К дополнительной информации',
              },
            },
          },
          {
            path: 'additional',
            name: 'Summary-additional',
            component: () =>
              import(
                /* webpackChunkName: "main-summary" */ '@/views/MedicineSummaryAdditional.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Дополнительная информация',
              role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
              ex_type: EX_SUMMARY,
              ex_type_sub: SUB_EX_SUMMARY,
              icon: 'mdi-book-information-variant',
            },
          },
        ],
        redirect: {
          name: 'Summary-Info',
        },
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: () =>
          import(
            /* webpackChunkName: "main-report" */ '@/views/MedicinePricing.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Сравнение с ценой в референтных странах',
          role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
          icon: 'mdi-earth',
          ex_type: EX_PRICING,
          ex_type_sub: SUB_EX_PRICING,
        },
      },
      {
        path: 'cost',
        name: 'Cost',
        component: () =>
          import(
            /* webpackChunkName: "main-report" */ '@/views/MedicineCost.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Расчёт затрат',
          role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
          icon: 'mdi-calculator',
          ex_type: EX_COST,
          ex_type_sub: SUB_EX_COST,
        },
      },
      {
        path: 'conclusion',
        name: 'Conclusion',
        component: () =>
          import(
            /* webpackChunkName: "main-conclusion" */ '@/views/MedicineConclusion'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Сводное заключение',
          role: roleCanExpertise,
          icon: 'mdi-medical-bag',
          ex_type: EX_CONCLUSION,
          ex_type_sub: SUB_EX_CONCLUSION,
        },
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () =>
          import(
            /* webpackChunkName: "main-summary" */ '@/views/MedicineReports.vue'
          ),
        meta: {
          hasMedicineMenu: true,
          requireAuth: true,
          caption: 'Заключения',
          role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
          icon: 'mdi-file-word-outline',
        },
        children: [
          {
            path: 'report',
            name: 'Report',
            component: () =>
              import(
                /* webpackChunkName: "main-report" */ '@/views/MedicineReport.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Выгрузка заключения КО',
              role: roleCanReport,
              icon: 'mdi-file-word-box-outline',
            },
          },
          {
            path: 'reportKO',
            name: 'ReportKO',
            component: () =>
              import(
                /* webpackChunkName: "main-report" */ '@/views/MedicineReportKO.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Заключение по комплексной оценке',
              role: roleCanReport,
              icon: 'mdi-file-word-box-outline',
            },
          },
          {
            path: 'reportMethod',
            name: 'ReportMethod',
            component: () =>
              import(
                /* webpackChunkName: "main-report" */ '@/views/MedicineReportMethod.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Заключение по оценке метод. кач.',
              role: roleCanReport,
              icon: 'mdi-file-word-box-outline',
            },
          },
          {
            path: 'report-conclusion',
            name: 'reportConclusion',
            component: () =>
              import(
                /* webpackChunkName: "main-report" */ '@/views/MedicineReportConclusion'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Выгрузка сводного заключения',
              role: roleCanConclusionReport,
              icon: 'mdi-file-word-box-outline',
            },
          },
          {
            path: 'report-presentation',
            name: 'ReportPresentation',
            component: () =>
              import(
                /* webpackChunkName: "main-report" */ '@/views/MedicineReportPresent.vue'
              ),
            meta: {
              hasMedicineMenu: true,
              requireAuth: true,
              caption: 'Выгрузка представления',
              role: [ADMIN, EXPERT, FOREIGN, CONSEQUENCES],
              icon: 'mdi-file-word-box-outline',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/references',
    name: 'ReferencesPage',
    component: () =>
      import(/* webpackChunkName: "refs" */ '@/views/ReferencesPage.vue'),
    meta: {
      mainMenu: true,
      requireAuth: true,
      title: 'Справочники',
      icon: 'mdi-book-multiple-outline',
    },
    children: [
      {
        path: 'companies',
        name: 'RefCompanies',
        component: () =>
          import(
            /* webpackChunkName: "refs" */ '@/views/ReferencesCompanies.vue'
          ),
        meta: {
          requireAuth: true,
          title: 'Компании заявители',
          icon: 'mdi-book-account-outline',
        },
      },
      {
        path: 'medicine',
        name: 'RefMedicine',
        component: () =>
          import(
            /* webpackChunkName: "refs" */ '@/views/ReferencesMedicine.vue'
          ),
        meta: {
          requireAuth: true,
          title: 'Справочник ЛП',
          icon: 'mdi-book-outline',
        },
      },
      {
        path: 'indications',
        name: 'RefIndications',
        component: () =>
          import(
            /* webpackChunkName: "refs" */ '@/views/ReferencesIndications.vue'
          ),
        meta: {
          requireAuth: true,
          title: 'Справочник показаний',
          icon: 'mdi-notebook-outline',
        },
      },
      {
        path: 'kei',
        name: 'RefKei',
        component: () =>
          import(/* webpackChunkName: "refs" */ '@/views/ReferencesKei.vue'),
        meta: {
          requireAuth: true,
          title: 'Шаблоны КЭИ',
          icon: 'mdi-book-edit-outline',
        },
      },
      {
        path: 'answer-kei',
        name: 'RefAnswerKei',
        component: () =>
          import(
            /* webpackChunkName: "RefAnswerKei" */ '@/views/ReferencesKeiAnswers'
          ),
        meta: {
          requireAuth: true,
          title: 'Ответы КЭИ',
          icon: 'mdi-book-edit-outline',
        },
      },
      {
        path: 'avb',
        name: 'RefAvb',
        component: () =>
          import(/* webpackChunkName: "refs" */ '@/views/ReferencesAvb.vue'),
        meta: {
          requireAuth: true,
          title: 'Шаблоны АВБ',
          icon: 'mdi-book-edit-outline',
        },
      },
      {
        path: 'answer-avd',
        name: 'RefAnswerAvb',
        component: () =>
          import(
            /* webpackChunkName: "RefAnswerKei" */ '@/views/ReferencesAvbAnswers'
          ),
        meta: {
          requireAuth: true,
          title: 'Ответы АВБ',
          icon: 'mdi-book-edit-outline',
        },
      },
      {
        path: 'mkb10',
        name: 'MKB10',
        component: () =>
          import(/* webpackChunkName: "refs" */ '@/views/ReferencesMKB10.vue'),
        meta: {
          requireAuth: true,
          title: 'МКБ-10',
          icon: 'mdi-book-education-outline',
        },
      },
      {
        path: 'summary',
        name: 'SUMMARY',
        component: () =>
          import(
            /* webpackChunkName: "refs" */ '@/views/ReferencesSummary.vue'
          ),
        meta: {
          requireAuth: true,
          title: 'Представление о ЛП',
          icon: 'mdi-book-account-outline',
        },
      },
      {
        path: 'cr',
        name: 'CR',
        component: () =>
          import(/* webpackChunkName: "refs" */ '@/views/ReferencesCr.vue'),
        meta: {
          requireAuth: true,
          title: 'Клинические рекомендаций',
          icon: 'mdi-book-cross',
        },
      },
    ],
    redirect: {
      name: 'RefCompanies',
    },
  },
  {
    path: '/logs',
    name: 'UserLogs',
    component: () =>
      import(/* webpackChunkName: "log" */ '@/views/UserLogs.vue'),
    meta: {
      mainMenu: true,
      requireAuth: true,
      role: [ADMIN],
      title: 'Действия пользователей',
      icon: 'mdi-desktop-classic',
    },
  },
  // прочие роуты
  {
    path: '/login',
    name: ROUTE_LOGIN,
    component: () =>
      import(/* webpackChunkName: "user" */ '@/views/LoginView.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/views/ResetPassword.vue'),
    meta: {
      title: 'Сброс пароля',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function getDocumentTitle(to) {
  let res
  const { title, hasMedicineMenu, caption } = to.meta

  if (typeof title === 'string') res = title
  if (typeof title === 'function') {
    res = title()
  }

  // если в меню препарата и нет явного заголовка
  if (hasMedicineMenu) {
    const MNN = store.getters.storedPlpMNN
    res = MNN ? `${MNN} - ${caption}` : caption
  }
  return res ? res : 'ЦЭККМП : Платформа Информатизации Комплексной Оценки'
}

router.beforeEach((to, from, next) => {
  if (store.getters.hasLoginProceed) {
    //Api.cancelUnauthorizedEventWaitQuery() // Можно попытаттся отчистить все кто ждёт авториацию
    return next(false)
  }

  // на логин не идём если уже залогины
  const isLoggedIn = store.getters.isLoggedIn

  if (to.name === ROUTE_LOGIN && isLoggedIn) {
    router.push({ path: '/' }).catch(() => {})
  }

  // Отбой если есть не сохранённые данные
  if (isLoggedIn && store.getters.hasUnsavedChanges) {
    Vue.$toast.warning(
      'Не могу уйти со страницы. Есть несохраненные изменения',
      { timeout: 5000 }
    )
    return next(false)
  }

  // Отбой если ешё в процессе загрузки
  if (isLoggedIn && store.getters.hasLoading) {
    Vue.$toast.warning(
      'Не могу уйти со страницы. Не завершена загрузка данных',
      { timeout: 5000 }
    )
    return next(false)
  }

  if (
    to.matched.some(record => record.meta.requireAuth) &&
    to.name !== ROUTE_LOGIN
  ) {
    if (!isLoggedIn) {
      const redirect = to.fullPath ?? undefined
      router.push({ name: ROUTE_LOGIN, query: { redirect } }).catch(() => {})
      return
    }
  }

  // Отбой если роут закрыт для этой роли
  if (
    to.meta.requireAuth &&
    Array.isArray(to.meta.role) &&
    !to.meta.role.includes(store.getters.userRole)
  ) {
    Vue.$toast.error('403 - Доступ закрыт')
    return
  }

  next()
})

router.afterEach(to => {
  document.title = getDocumentTitle(to)
})

export default router
