import apiCall from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'

const dictCr = 'crDict'

const state = {
  // Info
  dictCrInfo: ls.loadDictVersion(dictCr, {}),
  dictCrVersion: ls.load(dictCr) || '',
  // СПРАВОЧНИК
  dictCr: null,
}

const getters = {
  dictCrVersion: state => state.dictCrVersion,
  dictCrDescription: (_, getters) => getters.dictCrInfo.description ?? '...',
  dictCrInfo: state => state.dictCrInfo || {},
  dictCr: state => state.dictCr || [],
}

const mutations = {
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_CR_DICT_LOCAL(state) {
    try {
      state.dictCrVersion = ls.load(dictCr, {})
      state.dictCr = ls.loadDictData(dictCr)
    } catch {
      state.dictCrInfo = {}
      state.dictCr = null
    }
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_CR_DICT(state, { version, description, data }) {
    state.dictCrInfo = { version, description }
    state.dictCr = data
    ls.saveDict(dictCr, { version, description }, data)
  },
}

const actions = {
  /**  ПОЛУЧАЕМ СПРАВОЧНИК ЛП ДЛЯ ГВС, СРАВНИВАЕМ ВЕРСИИ */
  GET_CR_DICT: async ({ commit, getters }) => {
    const currentVersion = getters.dictСrVersion
    try {
      const { version, description, data } = await apiCall(
        `dict/cr?version=${currentVersion}`
      )
      if (data) {
        commit('SET_CR_DICT', {
          version,
          description,
          data,
        })
      } else {
        commit('GET_CR_DICT_LOCAL')
      }
      return getters.dictCr
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник KR',
        text: 'Ошибка загрузки справочника KR',
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
