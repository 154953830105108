import apiCall from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'

/* Справочник лекарственных препаратов */

const dictName = 'lfDict'

const state = {
  // Info
  dictMedicineInfo: ls.loadDictVersion(dictName, {}),
  // СПРАВОЧНИК
  dictMedicine: null,
}

const getters = {
  dictMedicineVersion: (_, getters) => getters.dictMedicineInfo.version ?? '',
  dictMedicineDescription: (_, getters) =>
    getters.dictMedicineInfo.description ?? '...',
  dictMedicineInfo: state => state.dictMedicineInfo || {},
  dictMedicine: state => state.dictMedicine || [],
  dictMedicineYear: (_, getters) => getters.dictMedicine[0]?.yearData ?? null,

  // Заглушка - получаем справочник атх-мнн из справочника Льва
  dictMedicineAtc5Mnn: (_, getters) =>
    getters.dictMedicine
      .reduce((arr, { id, isJVNLP, atxGroupCode: ath, innInternal: mnn }) => {
        if (isJVNLP) {
          arr.push({ id, ath, mnn })
        }
        return arr
      }, [])
      .sort((a, b) => String(a?.mnn).localeCompare(b?.mnn)),
}

const mutations = {
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_MEDICINE_DICT_LOCAL(state) {
    try {
      state.dictMedicineInfo = ls.loadDictVersion(dictName, {})
      state.dictMedicine = ls.loadDictData(dictName)
    } catch {
      state.dictMedicineInfo = {}
      state.dictMedicine = null
    }
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_MEDICINE_DICT(state, { version, description, data }) {
    state.dictMedicineInfo = { version, description }
    state.dictMedicine = data
    ls.saveDict(dictName, { version, description }, data)
  },
}

const actions = {
  /**  ПОЛУЧАЕМ СПРАВОЧНИК ЛП ДЛЯ ПЕРЕЧНЯ АНАЛОГОВ, СРАВНИВАЕМ ВЕРСИИ */
  GET_MEDICINE_DICT: async ({ commit, getters, state }) => {
    if (state.dictMedicine) return
    const currentVersion = getters.dictMedicineVersion
    try {
      const { version, description, data } = await apiCall(
        `dict/lev?version=${currentVersion}`
      )
      if (data) {
        commit('SET_MEDICINE_DICT', {
          version,
          description,
          data,
        })
      } else {
        commit('GET_MEDICINE_DICT_LOCAL')
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник ЛП',
        text: 'Ошибка загрузки справочника ЛП',
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
