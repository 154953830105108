import { Api } from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'

const dictName = 'dictMKB'

const state = {
  // Info
  dictMKBInfo: ls.loadDictVersion(dictName, {}),
  // СПРАВОЧНИК МКБ
  dictMKBData: null,
}

const getters = {
  dictMKBVersion: (_, getters) => getters.dictMKBInfo.version,
  dictMKBDescription: (_, getters) => getters.dictMKBInfo.description,
  dictMKBInfo: state => state.dictMKBInfo || {},
  dictMKBData: state => state.dictMKBData || [],
}

const mutations = {
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_MKB_LOCAL(state) {
    if (!state.dictMKBData)
      try {
        state.dictMKBInfo = ls.loadDictVersion(dictName, {})
        state.dictMKBData = ls.loadDictData(dictName)
      } catch (error) {
        state.dictMKBInfo = {}
        state.dictMKBData = null
      }
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_MKB_DICT(state, { version, description, data }) {
    state.dictMKBInfo = { version, description }
    state.dictMKBData = data
    ls.saveDict(dictName, { version, description }, data)
  },
}

const actions = {
  /**
   * ПОЛУЧАЕМ СТПРАВОЧНИК И СВРАВНИВАЕМ ВЕРСИИ...
   */
  GET_MKB_DICT: async ({ commit, getters }) => {
    const currentVersion = getters.dictMKBVersion
    try {
      const { version, description, data } = await Api.get(
        `dict/mkb10?version=${currentVersion || ''}`
      )
      if (data) {
        // всем в корне сделаем признак root = true
        data.forEach(elem => {
          elem.root = true
        })
        commit('SET_MKB_DICT', { version, description, data })
      } else {
        commit('GET_MKB_LOCAL')
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник МКБ-10',
        text: 'Ошибка загрузки справочника МКБ-10',
        error,
      })
      throw error
    }
  },
  IMPORT_MKB_DICT: async ({ commit, dispatch }) => {
    try {
      const { messages } = await Api.post(`dict/mkb10/nsi/import`)
      if (!messages?.[0]) {
        commit('SET_MKB_DICT', {})
        await dispatch('GET_MKB_DICT')
      }
      return messages?.[0] || 'Справочник МКБ-10 обновлён успешно!'
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник МКБ-10',
        text: 'Ошибка обновления справочника МКБ-10',
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
