import apiCall from '@/lib/axiosApi'
import { ADMIN, CONSEQUENCES, EXPERT, FOREIGN } from '@/store/const/userRoles'
import Vue from 'vue'
import { copyObject } from '@/lib/objects'

export class EReferencesException extends Error {
  constructor(message, reference, error) {
    super(`[${reference}] ${message}`)
    this.name = 'EReferencesException'
    this.error = error
  }
}

const state = {
  userRoles: null, //список пользователей системы
  storeQuarters: null, //кварталы и года
  storeExpTypes: null, //список экспертиз
  companies: null, // список компаний
  choosedQuarter: 'all', //Выбранный ID Года и Квартала
}

const getters = {
  userRoles: state => state.userRoles,
  storeQuarters: state => state.storeQuarters || [],
  storeExpTypes: state => state.storeExpTypes || [],
  companiesList: state => state.companies || [],
  choosedQuarter: state => state.choosedQuarter || 'all',

  ADMIN_ID: state =>
    state.userRoles?.find(role => role.name === ADMIN)?.id ?? -1,
  EXPERT_ID: state =>
    state.userRoles?.find(role => role.name === EXPERT)?.id ?? -1,
  CONSEQUENCES_ID: state =>
    state.userRoles?.find(role => role.name === CONSEQUENCES)?.id ?? -1,
  FOREIGN_ID: state =>
    state.userRoles?.find(role => role.name === FOREIGN)?.id ?? -1,
}

const mutations = {
  set_extypes(state, types) {
    state.storeExpTypes = types
  },
  set_roles(state, roles) {
    state.userRoles = roles
  },
  set_quarters(state, quarters) {
    state.storeQuarters = quarters
  },
  set_companies(state, companies) {
    state.companies = companies
  },
  set_company(state, company) {
    const companyIndex = state.companies?.findIndex(
      ({ id }) => id === company.id
    )
    if (companyIndex > -1) {
      Vue.set(state.companies, companyIndex, {
        ...state.companies[companyIndex],
        ...company,
      })
    } else {
      state.companies.push(company)
    }
  },
  set_company_users(state, { companyId, users }) {
    const companyIndex = state.companies?.findIndex(
      ({ id }) => id === companyId
    )
    if (companyIndex > -1) {
      Vue.set(state.companies[companyIndex], 'users', users)
    }
  },
  set_choosed_quarter(state, quarter) {
    state.choosedQuarter = quarter
  },
}

const actions = {
  /** получаем доступные типы экспертиз */
  async get_extypes({ commit }) {
    commit('set_extypes', null)
    try {
      const data = await apiCall('expertise-types')
      commit('set_extypes', data)
      return data
    } catch (error) {
      throw new EReferencesException(
        'Ошибка загрузки справочника типов экспертиз',
        'expertise-types',
        error
      )
    }
  },
  /** получаем доступные роли пользователей */
  async get_roles({ getters, commit }) {
    try {
      if (!getters.userRoles) {
        const data = await apiCall('roles')
        commit('set_roles', data)
        return data
      } else {
        // если что из стора, так как роли почти не будут меняться
        return getters.userRoles
      }
    } catch (error) {
      throw new EReferencesException(
        'Ошибка загрузки справочника ролей пользователей',
        'roles',
        error
      )
    }
  },
  /** получаем доступные кварталы - по годам (все где есть проекты PLP) */
  async get_quarters({ commit }) {
    commit('set_quarters', null)
    try {
      const quarters = (await apiCall('plp/get-years-and-quarters')) ?? []
      commit('set_quarters', quarters)
      return quarters
    } catch (error) {
      throw new EReferencesException(
        'Ошибка загрузки справочника доступных кварталов',
        'years-and-quarters',
        error
      )
    }
  },
  /** получаем список доступных компаний */
  async get_companies({ getters, commit }, force) {
    if (!force && getters.companiesList.length) return
    //commit('set_companies', null)
    try {
      const data = await apiCall('companies')
      commit('set_companies', data)
    } catch (error) {
      commit('SET_ERROR', { head: 'Компании', error })
      throw new EReferencesException(
        'Ошибка загрузки списка компаний',
        'companies',
        error
      )
    }
  },
  /** получаем **companyId** - компанию с юзерами и data.titles - списком названий */
  async get_company({ commit }, companyId) {
    console.log(`LOADING COMPANY DATA FOR company_id=${companyId}`)
    try {
      const company = await apiCall(`companies/${companyId}`)
      commit('set_company', company)
      return company
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Компании',
        text: `Ошибка получения компании id: ${companyId}`,
        error,
      })
      throw new EReferencesException(
        `Ошибка загрузки компании ${companyId}`,
        'companies',
        error
      )
    }
  },
  async add_company({ commit, dispatch }, company) {
    try {
      company = await apiCall('companies', company, 'POST')
      commit('set_company', company)
    } catch (error) {
      dispatch('get_companies')
      commit('SET_ERROR', {
        head: 'Компании',
        text: 'Ошибка добавления компании',
        error,
      })
      throw error
    }
  },
  async save_company({ commit, dispatch }, company) {
    try {
      company = await apiCall(`companies/${company.id}`, company, 'PATCH')
      commit('set_company', company)
    } catch (error) {
      dispatch('get_companies')
      commit('SET_ERROR', {
        head: 'Компании',
        text: 'Ошибка сохранения компании',
        error,
      })
      throw error
    }
  },
  async delete_company({ commit, dispatch }, id) {
    try {
      await apiCall(`companies/${id}`, undefined, 'DELETE')
      dispatch('get_companies', true)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Компании',
        text: 'Ошибка удаления компании',
        error,
      })
      throw new Error('Ошибка удаления компании')
    }
  },
  async add_user_to_company({ commit }, { companyId, userId }) {
    try {
      const company = await apiCall(
        `companies/${companyId}/add-user/${userId}`,
        undefined,
        'POST'
      )
      commit('set_company', company)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Компании',
        text: 'Ошибка добавления сотрудника в компанию',
        error,
      })
      throw error
    }
  },
  async delete_user_from_company({ dispatch, commit }, { companyId, userId }) {
    try {
      await apiCall(
        `companies/${companyId}/remove-user/${userId}`,
        undefined,
        'DELETE'
      )
      dispatch('get_company', companyId)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Компании',
        text: 'Ошибка удаления пользователя из компании',
        error,
      })
      throw new Error('Ошибка удаления пользователя из компании')
    }
  },

  /** ищем компанию по альтернативному названию */
  async company_search(_, title) {
    try {
      return await apiCall(`companies/search`, { title }, 'POST')
    } catch (error) {
      console.info(`Ошибка поиска компании: "${title}" - ${error}`)
      throw new Error(`Ошибка поиска компании: ${title} - ${error}`)
      // return null
    }
  },
  async add_company_title({ dispatch }, { companyId, applicant }) {
    const company = copyObject(await dispatch('get_company', companyId))
    const titles = company?.data?.titles || []
    if (titles.includes(applicant)) return
    titles.push(applicant)
    company.data = { ...company.data, titles }
    await dispatch('save_company', company)
  },

  async get_choosed_quarter({ commit }) {
    commit('set_choosed_quarter', 'all')
    try {
      const data =
        (await JSON.parse(localStorage.getItem('OTZ/HTA/YaQ'))) || 'all'
      commit('set_choosed_quarter', data)
    } catch (error) {
      commit('SET_ERROR', { head: 'Интервал', error })
      throw new EReferencesException(
        'Ошибка загрузки настройки интервала в LS',
        'Local Storage',
        error
      )
    }
  },

  save_choosed_quarter({ commit }, id = 'all') {
    try {
      if (id !== null) {
        localStorage.setItem('OTZ/HTA/YaQ', JSON.stringify(id))
      }

      commit('set_choosed_quarter', id)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Интервал',
        text: 'Ошибка сохранения настройки интервала в LS',
        error,
      })
      throw new Error('Ошибка сохранения интервала')
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
