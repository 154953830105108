import lz from 'lz-string'

const cDATA = 'Data'
const cVERSION = 'Version'
const cLZ = 'Lz'

function compressData(uncompressed) {
  return lz.compressToBase64(uncompressed)
}

function decompressData(compressed) {
  return lz.decompressFromBase64(compressed)
}

export default class LocalStorageHelper {
  static #sKey(key) {
    return String(key ?? 'default')
  }
  /**
   * Сохраняет пару ключ значение в LocalStorage
   * @param {string} key - ключ строка
   * @param {string} value - приводим к строке
   */
  static save(key, value, compress = false) {
    const sKey = this.#sKey(key)
    try {
      if (compress) {
        localStorage.setItem(`${sKey}${cLZ}`, compressData(String(value ?? '')))
        // если сохранили сжатую удаляем не сжатую
        localStorage.removeItem(sKey)
      } else {
        localStorage.setItem(sKey, String(value ?? ''))
        // если сохранили не сжатую удаляем сжатую
        localStorage.removeItem(`${sKey}${cLZ}`)
      }
    } catch (err) {
      this.clear(sKey)
      throw err
    }
  }
  /**
   * Сохраняет пару ключ значение (объект) в LocalStorage
   * @param {string} key - ключ строка
   * @param {object} objValue - приводим к JSON - строке
   */
  static saveObj(key, objValue, compress = false) {
    const sKey = this.#sKey(key)
    try {
      objValue = objValue ? JSON.stringify(objValue) : ''

      if (compress) {
        localStorage.setItem(`${sKey}${cLZ}`, compressData(objValue))
        // если сохранили сжатую удаляем не сжатую
        localStorage.removeItem(sKey)
      } else {
        localStorage.setItem(sKey, objValue)
        // если сохранили не сжатую удаляем сжатую
        localStorage.removeItem(`${sKey}${cLZ}`)
      }
    } catch (err) {
      this.clearObj(sKey)
      throw err
    }
  }

  /**
   * Возвращает значение по ключу из LocalStorage
   * @param {string} key - ключ строка
   * @param {string} defValue - значение если ключа нет
   */
  static load(key, defValue = '') {
    const sKey = this.#sKey(key)
    let value = decompressData(localStorage.getItem(`${sKey}${cLZ}`) ?? '')
    if (!value) localStorage.getItem(sKey)

    return value ?? String(defValue)
  }

  /**
   * Возвращает значение по ключу из LocalStorage
   * @param {string} key - ключ строка
   * @param {string} defValue - значение если ключа нет
   */
  static loadObj(key, defValue = null) {
    const sKey = this.#sKey(key)
    // сначала ищем сжатую
    let value = decompressData(localStorage.getItem(`${sKey}${cLZ}`))
    if (!value) value = localStorage.getItem(sKey)

    try {
      return value ? JSON.parse(value) : defValue
    } catch {
      return defValue
    }
  }

  static clear(key) {
    localStorage.removeItem(this.#sKey(key))
  }

  static clearObj(key) {
    localStorage.removeItem(this.#sKey(key))
    localStorage.removeItem(`${this.#sKey(key)}${cLZ}`)
  }

  static trySaveVersObj(keyVers, version, keyData, data, compress = false) {
    try {
      this.save(keyVers, version, false)
      this.saveObj(keyData, data, compress)
      return true
    } catch {
      this.clear(keyVers)
      this.clearObj(keyData)
      return false
    }
  }

  /******************************************************/

  // eslint-disable-next-line no-dupe-class-members
  static #sDictKey(name, keyData = cDATA, keyVersion = cVERSION) {
    const sKey = String(name ?? 'default')
    const kVersion = `${sKey}${keyVersion ?? cVERSION}`
    const kDataDef = sKey
    const kData = `${sKey}${keyData}`
    const kDataLZ = `${kData}${cLZ}`

    const keys = { kVersion, kDataDef, kData, kDataLZ }
    return keys
  }

  /**
   * Сохраняет справочник в LocalStorage
   * @param {string} name - название справочника
   * @param {string} version - версия справочника
   * @param {*} data - справочник
   * @param {boolean} compress - использовать сжатия lz-string
   * @param {string} keyData - постфиксКлюча (Data)
   * @param {string} keyVersion - постфиксВерсии (Version)
   */
  static saveDict(
    name,
    version,
    data,
    compress = true,
    keyData = cDATA,
    keyVersion = cVERSION
  ) {
    const keys = this.#sDictKey(name, keyData, keyVersion)
    try {
      if (!data) throw new Error('No data -> clear dict')
      const sData = JSON.stringify(data)

      if (compress) {
        localStorage.setItem(keys.kDataLZ, compressData(sData))
        localStorage.removeItem(keys.kData)
      } else {
        localStorage.setItem(keys.kData, sData)
        localStorage.removeItem(keys.kDataLZ)
      }
      if (keys.kDataDef !== keys.kData) {
        localStorage.removeItem(keys.kDataDef)
      }

      // сохраняем версию ( если объект то stringify)
      if (version && typeof version === 'object') {
        localStorage.setItem(keys.kVersion, JSON.stringify(version))
      } else {
        localStorage.setItem(keys.kVersion, String(version ?? ''))
      }
    } catch {
      this.clearDict(name, keyData, keyVersion)
    }
  }
  /**
   * Восстанавливаем версию справочника из LocalStorage
   * @param {string} name - название справочника
   * @param {string} keyVersion - постфиксВерсии (Version)
   * @returns
   */
  static loadDictVersion(name, defVersion = '', keyVersion = cVERSION) {
    const keys = this.#sDictKey(name, cDATA, keyVersion)
    const sVersion = localStorage.getItem(keys.kVersion)
    // если объект то парсим
    if (typeof defVersion === 'object') {
      return ['{', '['].includes(String(sVersion).charAt(0))
        ? JSON.parse(sVersion)
        : defVersion
    } else {
      return sVersion ?? defVersion
    }
  }

  /**
   * Восстанавливаем справочник из LocalStorage
   * @param {string} name - название справочника
   * @param {string} keyData - постфиксКлюча (Data)
   * @param {string} keyVersion - постфиксВерсии (Version)
   * @returns справочник
   */
  static loadDictData(name, keyData = cDATA, keyVersion = cVERSION) {
    const keys = this.#sDictKey(name, keyData, keyVersion)
    try {
      const version = localStorage.getItem(keys.kVersion)
      if (!version) return null

      // сначала по ключу nameDataLz
      let sData = decompressData(localStorage.getItem(keys.kDataLZ) ?? '')
      if (!sData) {
        // если нет то по ключу nameData
        sData = localStorage.getItem(keys.kData)
      }
      if (!sData && keys.kDataDef !== keys.kData) {
        // если нет то по ключу name
        sData = localStorage.getItem(keys.kDataDef)
      }
      // если данных нет чистим всё
      if (!sData) {
        throw new Error('No data -> clear dict')
      }
      return JSON.parse(sData)
    } catch {
      this.clearDict(name, keyData, keyVersion)
      throw new Error(
        'Ошибка восстановления справочника из LocalStorage, обновите страницу!'
      )
    }
  }

  /**
   * Восстанавливаем справочник из LocalStorage
   * @param {string} name - название справочника
   * @param {*} defValue - значение по умолчанию (или если ошибка)
   * @param {string} keyData - постфиксКлюча (Data)
   * @param {string} keyVersion - постфиксВерсии (Version)
   * @returns справочник
   */
  static loadDictDataDef(
    name,
    defValue,
    keyData = cDATA,
    keyVersion = cVERSION
  ) {
    try {
      return this.loadDictData(name, keyData, keyVersion)
    } catch {
      return defValue
    }
  }

  static clearDict(name, keyData = cDATA, keyVersion = cVERSION) {
    const keys = this.#sDictKey(name, keyData, keyVersion)

    for (const key in keys) {
      if (Object.hasOwnProperty.call(keys, key)) {
        localStorage.removeItem(keys[key])
      }
    }
  }

  /******************************************************/
}
