/*
  Хранилише для одиночной работы с экспертизами (для эксперта)
*/
import apiCall, { Api } from '@/lib/axiosApi'
import {
  sub_exp_migration,
  sub_exp_version,
  SUB_EXPERTISE_FOR_REPORT,
} from './../const/expertise'
import Vue from 'vue'

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = error => reject(error)
  })

export class EPLPException extends Error {
  constructor(message) {
    super(message)
    this.name = 'EPLPException'
  }
}
export class EPlpNotValidExpertise extends EPLPException {
  constructor() {
    super('Неопределены параметры экспертизы!')
    this.name = 'EPlpNotValidExpertise'
  }
  static check({ isValidExpertise }, raise = true) {
    if (!isValidExpertise && raise) throw new this()
    return isValidExpertise
  }
}

export class EPlpLoadingExpertise extends EPLPException {
  constructor() {
    super('Экспертиза находится в стадии загрузки!')
    this.name = 'EPlpLoadingExpertise'
  }
  static check({ loadingExp }, raise = true) {
    if (loadingExp && raise) throw new this()
    return loadingExp
  }
}

export class EPlpGetExpertise extends EPLPException {
  constructor(ex_type, ex_type_sub, error) {
    super(
      `Ошибка получение экспертизы "${ex_type}(${ex_type_sub})", экспертиза ещё не существует или задача не распределена!`
    )
    this.name = 'EPlpGetExpertise'
    this.error = error
  }
}

const state = {
  // Данные по проекту PLP
  loadingPlp: false,
  loadingPlpData: false,
  storedPlp: {},

  // селекторы экспертизы
  ex_type: null,
  ex_type_sub: null,

  loadingExp: 0,
  savingExp: false,

  // список версий
  loadingVersionList: 0,
  versionList: null,
  versionListReady: false,
  // текущая подэкспертиза
  currentExpertise: null,
}

const getters = {
  // стутус PLP в загрузке
  loadingPlp: state => state.loadingPlp,
  loadingPlpData: state => state.loadingPlpData,
  // Текущий PLP
  storedPlp: state => state.storedPlp,
  storedPlpData: state => state.storedPlp?.data,
  storedPlpDataReady: state => !!state.storedPlp?.data,
  storedPlpYearQuarter: state => {
    if (!state.storedPlp) return null
    const { expertise_year, expertise_quarter } = state.storedPlp
    return `${expertise_year} / ${
      ['I', 'II', 'III', 'IV'][expertise_quarter - 1]
    }`
  },
  storedPlpVersion: state => state.storedPlp?.expertise_version || 1,
  // путь к файлам проекта на EXCHANGE
  storedPlpPath: state => state.storedPlp?.path ?? '\\\\',

  // plp_id
  storedPlpId: (state, getters) => getters.storedPlp?.id,
  // plp_mnn
  storedPlpMNN: (state, getters) => getters.storedPlp?.mnn,
  // подписант
  storedPlpSignerId: (state, getters) => getters.storedPlp?.user_signer_id,
  storedPlpSigner: (state, getters) => getters.storedPlp?.signer,
  // компания заявитель
  storedPlpCompanyId: (state, getters) => getters.storedPlp?.company_id,
  storedPlpCompany: (state, getters) => getters.storedPlp?.company,

  // Таски для текущего PLP
  storedPlpTasks: (state, getters) => getters.storedPlp?.tasks ?? [],
  // Таски распределённые на текущего юзера
  storedMyTasks: (state, getters) => {
    const user_id = getters.userID
    return getters.storedPlpTasks.filter(item => item.user_id === user_id)
  },
  //Крайние сроки
  storedPlpDeadlines: (state, getters) => getters.storedPlp?.deadlines,
  // активный тип экспертизы
  ex_type: state => state.ex_type ?? '',
  // активный тип под экспертизы
  ex_type_sub: state => state.ex_type_sub ?? '',
  // task для активной экспертизы
  plp_task: (state, getters) => {
    const task = getters.storedMyTasks.find(
      item => item.expertiseType.name === getters.ex_type
    )
    return task ?? null
  },
  // task_id для активной экспертизы
  plp_task_id: (state, getters) => {
    return getters.plp_task?.id
  },
  // экспертиза валидна для работы
  isValidExpertise: (state, getters) =>
    !!getters.plp_task_id && !!getters.ex_type && !!getters.ex_type_sub,

  // стутус экспертиза в загрузке
  loadingExp: (state, getters) => !!state.loadingExp || getters.loadingPlp,
  savingExp: state => state.savingExp,

  //подэкспертиза с версиями
  versionListReady: state => state.versionListReady,
  loadingVersionList: state => state.loadingVersionList,
  versionList: state => state.versionList,
  // текущая подэкспертиза
  subExpertise: state => state.currentExpertise,
  subExpertiseId: (state, getters) => getters.subExpertise?.id ?? null,
  subExpertiseUUID: (state, getters) =>
    getters.subExpertise?.version_uuid ?? null,
  subExpertiseName: (state, getters) =>
    getters.subExpertise?.version_name ?? null,
  subExpertiseData: state => state.currentExpertise?.data,
  subExpertiseDataVersion: state => state.currentExpertise?.data_version ?? 1,
  subExpertiseIsMainVersion: (state, getters) =>
    getters.subExpertise?.is_main_version ?? false,
  subExpertiseIsMerged: (state, getters) =>
    getters.subExpertise?.is_merged ?? false,
  // subExpertiseIsCompleted: (state, getters) =>
  //   getters.subExpertise?.is_completed ?? false,
  subExpertiseIsReadOnly: (state, getters) =>
    getters.plp_task?.is_checked ||
    !getters.subExpertiseIsMainVersion ||
    getters.subExpertise?.is_readonly,
}

const mutations = {
  SET_DEFAULT(state, full = true) {
    console.log('PLP SET_DEFAULT')

    state.storedPlp = {}

    if (full) {
      state.ex_type = null
      state.ex_type_sub = null
    }

    state.versionListReady = false
    state.versionList = null
    state.currentExpertise = null

    state.loadingExp = 0
  },
  SET_LOADING(state, st) {
    state.loadingPlp = !!st
  },
  SET_PLP(state, plp) {
    state.storedPlp = { ...state.storedPlp, ...plp }
  },
  SET_LOADING_PLP_DATA(state, st) {
    state.loadingPlpData = !!st
  },
  SET_PLP_DATA(state, data) {
    console.log('SET_PLP_DATA')
    Vue.set(state.storedPlp, 'data', data || {})
  },
  UPDATE_PLP_TASK(state, task) {
    const index = state.storedPlp.tasks?.findIndex(({ id }) => id === task?.id)
    if (index !== undefined) {
      Vue.set(state.storedPlp.tasks, index, {
        ...state.storedPlp.tasks[index],
        ...task,
      })
    }
  },
  SET_LOADING_EXP(state, st) {
    state.loadingExp += st ? 1 : -1
  },
  SET_SAVING_EXP(state, st) {
    state.savingExp = !!st
  },
  SET_CURRENT_EXP(state, { ex_type, ex_type_sub }) {
    // меняем типы экспертизы
    state.ex_type = ex_type ?? null
    state.ex_type_sub = ex_type_sub ?? ''

    if (ex_type !== undefined)
      console.log('SET_CURRENT_EXP', state.ex_type, state.ex_type_sub)
    // зануляем всё что относится к экспертизе
    state.versionListReady = false
    state.versionList = null
    state.currentExpertise = null
  },
  SET_LOADING_VERSION_LIST(state, st) {
    state.loadingVersionList += st ? 1 : -1
  },
  SET_VERSION_LIST(state, list) {
    console.log('SET_VERSION_LIST', list?.length ? '' : 'is empty')
    state.versionList = list || []
    state.versionListReady = true
  },
  SET_CURRENT_EXPERTISE(state, data) {
    console.log('SET_CURRENT_EXPERTISE', data)
    state.currentExpertise = data
  },
}

const actions = {
  // меняем экспертизу по типу
  SET_CURRENT_EXP: async (
    { commit, getters, dispatch },
    { ex_type, ex_type_sub }
  ) => {
    // Если типы экспертизы не менялись уходим
    if (getters.ex_type === ex_type && getters.ex_type_sub === ex_type_sub)
      return
    try {
      // проверка - экспертиза на загрузке
      EPlpLoadingExpertise.check(getters)
      commit('SET_LOADING_EXP', true)

      commit('SET_CURRENT_EXP', { ex_type, ex_type_sub })
      // запустили обновление списка версии и далее
      if (getters.isValidExpertise) await dispatch('LOAD_VERSION_LIST')
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: 'Ошибка смены экспертизы',
        error,
      })
      throw error
    } finally {
      commit('SET_LOADING_EXP', false)
    }
  },
  // получаем список версии экспертизы
  LOAD_VERSION_LIST: async ({ commit, getters, dispatch }, loadOnly) => {
    try {
      // проверка на валидную экспертизу с генерацией ошибки
      EPlpNotValidExpertise.check(getters)

      commit('SET_LOADING_VERSION_LIST', true)

      const taskId = getters.plp_task_id
      const ex_type_sub = getters.ex_type_sub
      const data = await Api.get(
        `plp-task-subexpertise-versions/get-by-task-and-type/${taskId}/${ex_type_sub}?versions`
      )

      commit('SET_VERSION_LIST', data)

      // выход без смены текущей экспертизы
      if (loadOnly) return

      const uuid = data?.[0]?.version_uuid
      await dispatch('SET_EXPERTISE_VERSION', { uuid, inLoadingThread: true })
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: 'Ошибка загрузки списка версий',
        error,
      })
      throw error
    } finally {
      commit('SET_LOADING_VERSION_LIST', false)
    }
  },
  // выбираем версию по uuid
  SET_EXPERTISE_VERSION: async (
    { commit, getters },
    { uuid, inLoadingThread }
  ) => {
    try {
      if (uuid === getters.subExpertiseUUID) return
      // проверка - экспертиза на загрузке
      if (!inLoadingThread) EPlpLoadingExpertise.check(getters)
      // проверка на валидную экспертизу с генерацией ошибки
      EPlpNotValidExpertise.check(getters)
      commit('SET_LOADING_EXP', true)

      if (uuid) {
        const data = await Api.get(
          `plp-task-subexpertise-versions/get-by-version/${uuid}`
        )
        // мигрируем по версии v+
        let { data_version, data: expData } = data
        commit('SET_CURRENT_EXPERTISE', {
          ...data,
          ...(await sub_exp_migration(
            state.ex_type_sub,
            data_version,
            expData
          )),
        })
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: `Ошибка загрузки экспертизы ${getters.ex_type}:${getters.ex_type_sub}`,
        error,
      })
      throw error
    } finally {
      commit('SET_LOADING_EXP', false)
    }
  },
  // создание экспертизы
  CREATE_EXPERTISE: async ({ getters, commit, dispatch }, newData) => {
    try {
      // проверка - экспертиза на загрузке
      EPlpLoadingExpertise.check(getters)
      // проверка на валидную экспертизу с генерацией ошибки
      EPlpNotValidExpertise.check(getters)

      commit('SET_LOADING_EXP', true)
      commit('SET_SAVING_EXP', true)

      const dt = new Date()
      const version_name = `Версия от ${dt.toLocaleString()}`

      console.log(
        'CREATE_EXPERTISE',
        getters.ex_type,
        getters.ex_type_sub,
        version_name,
        newData
      )

      // достаём и сохраняем версию данных экспертизы
      const data_version = await sub_exp_version(getters.ex_type_sub)

      const data = await Api.post(`plp-task-subexpertise-versions`, {
        data: newData,
        version_name,
        plp_task_id: getters.plp_task_id,
        ex_type_name: getters.ex_type_sub,
        data_version,
      })

      // обновим список версий
      await dispatch('LOAD_VERSION_LIST')
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: `Ошибка создание экспертизы ${getters.ex_type}:${getters.ex_type_sub}`,
        error,
      })
      throw error
    } finally {
      commit('SET_SAVING_EXP', false)
      commit('SET_LOADING_EXP', false)
    }
  },

  COPY_EXPERTISE: async ({ getters, commit, dispatch }, newData) => {
    try {
      // проверка - экспертиза на загрузке
      EPlpLoadingExpertise.check(getters)
      // проверка на валидную экспертизу с генерацией ошибки
      EPlpNotValidExpertise.check(getters)

      const dt = new Date()
      const version_name = `Версия от ${dt.toLocaleString()}`

      commit('SET_LOADING_EXP', true)

      const data = await Api.post(`plp-task-subexpertise-versions`, {
        ...getters.subExpertise,
        version_name,
        data: newData || getters.subExpertiseData,
      })
      // обновим список версий
      await dispatch('LOAD_VERSION_LIST', true)
      commit('SET_CURRENT_EXPERTISE', data)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: `Ошибка копирования экспертизы ${getters.ex_type}:${getters.ex_type_sub}`,
        error,
      })
      throw error
    } finally {
      commit('SET_LOADING_EXP', false)
    }
  },

  /** Сохраняем текущую сабэкспертизу **ex_type**->**ex_type_sub**
   * @param exp_data.data данныеd формате JSON
   * @param exp_data.data_version версия формата объекта **.data** */
  SAVE_EXPERTISE: async ({ commit, dispatch, getters }, exp_data) => {
    try {
      // проверка - экспертиза на загрузке
      EPlpLoadingExpertise.check(getters)

      const { id, time_edit } = getters.subExpertise

      // проверка на валидную экспертизу с генерацией ошибки

      if (!EPlpNotValidExpertise.check(getters, false) || !id)
        throw new EPlpNotValidExpertise()

      // если экспертиза только для чтения выходим
      if (getters.subExpertiseIsReadOnly) return

      const change_name =
        exp_data.version_name &&
        exp_data.version_name !== getters.subExpertiseName

      commit('SET_SAVING_EXP', true)

      // достаём и сохраняем версию данных экспертизы
      const data_version = await sub_exp_version(getters.ex_type_sub)

      const data = await Api.patch(`plp-task-subexpertise-versions/${id}`, {
        ...exp_data,
        data_version,
        time_edit,
      })
      commit('SET_CURRENT_EXPERTISE', data)

      if (change_name) await dispatch('LOAD_VERSION_LIST', true)
    } catch (error) {
      if (error.code !== 409) {
        commit('SET_ERROR', {
          head: 'Экспертиза',
          text: `Ошибка сохранения экспертизы ${getters.ex_type}:${getters.ex_type_sub}`,
          error,
        })
      }
      throw error
    } finally {
      commit('SET_SAVING_EXP', false)
    }
  },

  /** Загружаем данные из последней версии экспертизы **ex_type**->**ex_type_sub**
   *  @param task_id определяется автоматом по ex_type.
   *  @description Если Задача не распределена - ошибка */
  async GET_MY_LAST_EXPERTISE_DATA({ getters }, { ex_type, ex_type_sub } = {}) {
    // по умолчанию  ex_type, ex_type_sub из геттеров
    const _ex_type = ex_type ?? getters.ex_type
    const _ex_type_sub = ex_type_sub ?? getters.ex_type_sub
    try {
      // Ищем task_id
      const task = getters.storedMyTasks.find(
        item => item.expertiseType.name === _ex_type
      )
      if (!task?.id)
        throw new Error(
          `Задача "${_ex_type}" не назначена для текущего пользователя.`
        )

      const {
        id,
        data_version,
        data: expData,
      } = await Api.get(
        `plp-task-subexpertise-versions/get-by-task-and-type/${task?.id}/${_ex_type_sub}`
      )

      if (!id) throw new Error(`Подэкспертиза "${_ex_type_sub}" не найдена.`)

      // мигрируем по версии v+
      const { data } = await sub_exp_migration(
        _ex_type_sub,
        data_version,
        expData
      )
      return data
    } catch (error) {
      throw new EPlpGetExpertise(_ex_type, _ex_type_sub, error)
    }
  },

  /** Создать новый PLP */
  CREATE_PLP: async ({ commit }, newPlp) => {
    try {
      const data = await Api.post(`plp`, newPlp)
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: 'Ошибка создания ПЛП',
        error,
      })
      throw error
    }
  },

  /** Обновляем активную задачу для текущего пользователя */
  UPDATE_PLP_TASK: async ({ commit, getters }, task) => {
    const id = getters.plp_task_id
    try {
      if (!id) throw new Error('Задача не определёна!')

      if (getters.loadingPlp)
        throw new Error('plp находится на стадии загрузки!')

      const data = await Api.patch(`plp-tasks/${id}`, task)

      commit('UPDATE_PLP_TASK', data)
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: 'Ошибка обновления текущей задачи',
        error,
      })
      throw error
    }
  },
  /** пробуем открыть активную задачу для текущего пользователя */
  UPDATE_PLP_TASK_REWORK: async ({ commit, getters }) => {
    const id = getters.plp_task_id
    try {
      if (!id) throw new Error('Задача не определёна!')

      if (getters.loadingPlp)
        throw new Error('plp находится на стадии загрузки!')

      await Api.post(`plp-tasks/${id}/send-to-rework`)

      commit('UPDATE_PLP_TASK', { id, is_completed: false })
      // обновить таски
      // dispatch('RELOAD_PLP')
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: 'Ошибка обновления текущей задачи',
        error,
      })
      throw error
    }
  },

  /** Обновляем данные в PLP */
  UPDATE_PLP: async ({ commit, getters }, plp) => {
    try {
      const id = plp.id || getters?.storedPlpId
      if (!id) throw new Error('plp не определён!')

      if (getters.loadingPlp || plp.loadingPlp)
        throw new Error('plp находится на стадии загрузки!')

      const data = await Api.patch(`plp/${id}`, plp, 'PATCH')

      commit('SET_PLP', { ...plp, ...data })
      if (plp.id) {
        commit('UPDATE_SETUP', data)
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: 'Ошибка обновления ПЛП',
        error,
      })
      throw error
    }
  },

  /** Получить PLP по id */
  GET_PLP: async ({ commit, getters }, plpId) => {
    if (plpId !== getters.storedPlpId)
      try {
        if (getters.loadingPlp)
          throw new Error('plp находится на стадии загрузки!')

        commit('SET_LOADING', true)
        commit('SET_DEFAULT')

        const data = await Api.get(`plp/${plpId}?addTasks`)
        commit('SET_PLP', data)
      } catch (error) {
        commit('SET_ERROR', {
          head: 'Экспертиза',
          text: 'Ошибка загрузки ПЛП',
          error,
        })
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
  },

  /** Перезагрузить текущую PLP */
  RELOAD_PLP: async ({ commit, getters, dispatch }, full) => {
    const pid = getters.storedPlpId
    commit('SET_DEFAULT', full)
    if (pid) dispatch('GET_PLP', pid)
  },

  /** Получаем данные для финального отчёта */
  GET_PLP_REPORT: async (
    { getters, commit },
    ex_types = SUB_EXPERTISE_FOR_REPORT || []
  ) => {
    const pid = getters.storedPlpId
    try {
      const report = await Api.post(`plp/${pid}/report`, { ex_types })
      // мигрируем по версии v+
      for (const ex_type_sub of ex_types) {
        const ex_type_sub_data_version = `${ex_type_sub}_data_version`
        const expData = report.expertise[ex_type_sub]
        const { data, data_version } = await sub_exp_migration(
          ex_type_sub,
          report.expertise[ex_type_sub_data_version] ?? 1,
          expData
        )
        report.expertise[ex_type_sub] = data
        report.expertise[ex_type_sub_data_version] = data_version
      }

      return report
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Отчёт',
        text: 'Ошибка получения/формирования отчёта',
        error,
      })
      throw error
    }
  },
  /** догружаем данные для текущей PLP */
  GET_PLP_DATA: async ({ commit, getters }) => {
    // если уже загружали или идёт загрузка выход
    if (getters.storedPlpDataReady || getters.loadingPlpData) return
    // иначе запрос данных
    try {
      const plpId = getters.storedPlpId
      if (!plpId) throw new Error('plp не загружен!')
      commit('SET_LOADING_PLP_DATA', true)

      const data = await Api.get(`plp/${plpId}?addData`)
      if (getters.storedPlpId === data.id) {
        commit('SET_PLP_DATA', data.data)
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Экспертиза',
        text: 'Ошибка загрузки ПЛП-описания',
        error,
      })
      throw error
    } finally {
      commit('SET_LOADING_PLP_DATA', false)
    }
  },
  CREATE_PLP_FILE: async ({ commit }, { plp_id, filename, file }) => {
    try {
      const contents = await toBase64(file)
      const data = await apiCall(
        'plp-files',
        { plp_id, filename, contents },
        'POST'
      )
      return data
    } catch (error) {
      commit('SET_ERROR', {
        head: 'PLP',
        text: 'Ошибка создания файла',
        error,
      })
      throw error
    }
  },
  GET_PLP_FILE: async ({ commit }, { uuid, filename }) => {
    try {
      await Api.getFile(`/plp-files/file/${uuid}`, filename)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'PLP',
        text: `Ошибка получения файла ${filename || '<undefined>'}`,
        error,
      })
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
